!(function (e) {
  const t = {};
  function r(n) {
    if (t[n]) return t[n].exports;
    const o = (t[n] = { i: n, l: !1, exports: {} });
    return e[n].call(o.exports, o, o.exports, r), (o.l = !0), o.exports;
  }
  (r.m = e),
    (r.c = t),
    (r.d = function (e, t, n) {
      r.o(e, t) ||
        Object.defineProperty(e, t, {
          configurable: !1,
          enumerable: !0,
          get: n,
        });
    }),
    (r.r = function (e) {
      Object.defineProperty(e, "__esModule", { value: !0 });
    }),
    (r.n = function (e) {
      const t =
        e && e.__esModule
          ? function () {
              return e.default;
            }
          : function () {
              return e;
            };
      return r.d(t, "a", t), t;
    }),
    (r.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }),
    (r.p = ""),
    r((r.s = 46));
})([
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
      function e(e, t) {
        for (let r = 0; r < t.length; r++) {
          const n = t[r];
          (n.enumerable = n.enumerable || !1),
            (n.configurable = !0),
            "value" in n && (n.writable = !0),
            Object.defineProperty(e, n.key, n);
        }
      }
      return function (t, r, n) {
        return r && e(t.prototype, r), n && e(t, n), t;
      };
    })();
    const o = (function () {
      function e(t, r, n) {
        const o =
          arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : null;
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, e),
          (this.group = (t || "").trim().toLowerCase()),
          (this.name = (r || "default").trim()),
          (this.controls = n || []),
          (this.deepLinkParams = []),
          o &&
            o.has(this.group) &&
            (this.deepLinkParams = o.get(this.group) || []);
      }
      return (
        n(e, [
          {
            key: "addControl",
            value: function (e) {
              e &&
                e.name === this.name &&
                e.group === this.group &&
                this.controls.push(e);
            },
          },
          {
            key: "getDeepLink",
            value: function () {
              return "";
            },
          },
        ]),
        e
      );
    })();
    t.default = o;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    t.default = function e(t) {
      !(function (e, t) {
        if (!(e instanceof t))
          throw new TypeError("Cannot call a class as a function");
      })(this, e),
        t &&
          ((this.element = t),
          (this.element.initialHTML = t.outerHTML),
          (this.type = (t.getAttribute("data-jplist-control") || "")
            .trim()
            .toLowerCase()),
          (this.group = (t.getAttribute("data-group") || "")
            .trim()
            .toLowerCase()),
          (this.name = (
            t.getAttribute("data-name") ||
            t.getAttribute("name") ||
            "default"
          ).trim()),
          (this.id = (t.getAttribute("data-id") || "").trim().toLowerCase()),
          (this.jump = (t.getAttribute("data-jump") || "").trim()));
    };
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = a(r(0)),
      i = a(r(8));
    function a(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const l = (function (e) {
      function t() {
        return (
          (function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
          (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (t.__proto__ || Object.getPrototypeOf(t)).apply(this, arguments)
          )
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, o.default),
        n(t, [
          {
            key: "getSortOptions",
            value: function () {
              let e = [],
                t = !0,
                r = !1,
                n = void 0;
              try {
                for (
                  var o, i = this.controls[Symbol.iterator]();
                  !(t = (o = i.next()).done);
                  t = !0
                ) {
                  const a = o.value;
                  e = e.concat(a.getSortOptions());
                }
              } catch (e) {
                (r = !0), (n = e);
              } finally {
                try {
                  !t && i.return && i.return();
                } finally {
                  if (r) throw n;
                }
              }
              return e;
            },
          },
          {
            key: "addControl",
            value: function (e) {
              if (e.name !== this.name || e.group !== this.group) return null;
              const t = new i.default(e.element);
              return this.controls.push(t), t;
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = a(r(0)),
      i = a(r(4));
    function a(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const l = (function (e) {
      function t() {
        return (
          (function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
          (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (t.__proto__ || Object.getPrototypeOf(t)).apply(this, arguments)
          )
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, o.default),
        n(t, [
          {
            key: "addControl",
            value: function (e) {
              if (e.name !== this.name || e.group !== this.group) return null;
              const t = new i.default(e.element);
              return this.controls.push(t), t;
            },
          },
          {
            key: "getPathFilterOptions",
            value: function () {
              let e = [],
                t = !0,
                r = !1,
                n = void 0;
              try {
                for (
                  var o, i = this.controls[Symbol.iterator]();
                  !(t = (o = i.next()).done);
                  t = !0
                ) {
                  const a = o.value;
                  e = e.concat(a.getPathFilterOptions());
                }
              } catch (e) {
                (r = !0), (n = e);
              } finally {
                try {
                  !t && i.return && i.return();
                } finally {
                  if (r) throw n;
                }
              }
              return e;
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    let n,
      o = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      i = r(1),
      a = (n = i) && n.__esModule ? n : { default: n };
    const l = (function (e) {
      function t(e) {
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, t);
        const r = (function (e, t) {
          if (!e)
            throw new ReferenceError(
              "this hasn't been initialised - super() hasn't been called"
            );
          return !t || ("object" != typeof t && "function" != typeof t) ? e : t;
        })(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this, e));
        return (
          e &&
            ((r.path = (e.getAttribute("data-path") || "").trim()),
            (r.isInverted =
              "true" ===
              (e.getAttribute("data-inverted") || "").toLowerCase().trim()),
            (r.or = e.getAttribute("data-or") || null)),
          r
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, a.default),
        o(t, [
          {
            key: "getPathFilterOptions",
            value: function () {
              return {
                path: this.path,
                isInverted: this.isInverted,
                or: this.or,
              };
            },
          },
          {
            key: "isEqualTo",
            value: function (e) {
              return this.path === e.path && this.isInverted === e.isInverted;
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = a(r(0)),
      i = a(r(13));
    function a(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const l = (function (e) {
      function t() {
        return (
          (function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
          (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (t.__proto__ || Object.getPrototypeOf(t)).apply(this, arguments)
          )
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, o.default),
        n(t, [
          {
            key: "getTextFilterOptions",
            value: function () {
              let e = [],
                t = !0,
                r = !1,
                n = void 0;
              try {
                for (
                  var o, i = this.controls[Symbol.iterator]();
                  !(t = (o = i.next()).done);
                  t = !0
                ) {
                  const a = o.value;
                  e = e.concat(a.getTextFilterOptions());
                }
              } catch (e) {
                (r = !0), (n = e);
              } finally {
                try {
                  !t && i.return && i.return();
                } finally {
                  if (r) throw n;
                }
              }
              return e;
            },
          },
          {
            key: "addControl",
            value: function (e) {
              if (e.name !== this.name || e.group !== this.group) return null;
              const t = new i.default(e.element);
              return this.controls.push(t), t;
            },
          },
          {
            key: "getDeepLink",
            value: function () {
              const e = this.controls
                .map(function (e) {
                  return e.id && "" !== e.text.trim()
                    ? e.id + "=" + e.text.trim()
                    : "";
                })
                .filter(function (e) {
                  return "" !== e;
                });
              return Array.from(new Set(e)).join("&");
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
      function e(e, t) {
        for (let r = 0; r < t.length; r++) {
          const n = t[r];
          (n.enumerable = n.enumerable || !1),
            (n.configurable = !0),
            "value" in n && (n.writable = !0),
            Object.defineProperty(e, n.key, n);
        }
      }
      return function (t, r, n) {
        return r && e(t.prototype, r), n && e(t, n), t;
      };
    })();
    r(53);
    const o = (function () {
      function e(t) {
        if (
          ((function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, e),
          t)
        ) {
          if (((this.element = t), !this.element)) return;
          (this.panels = this.element.querySelectorAll('[data-type="panel"]')),
            (this.element.openedClass = (
              this.element.getAttribute("data-opened-class") ||
              "jplist-dd-opened"
            ).trim());
          let r = !0,
            n = !1,
            o = void 0;
          try {
            for (
              var i, a = this.panels[Symbol.iterator]();
              !(r = (i = a.next()).done);
              r = !0
            ) {
              const l = i.value;
              (l.initialContent = l.innerHTML), (l.element = t);
            }
          } catch (e) {
            (n = !0), (o = e);
          } finally {
            try {
              !r && a.return && a.return();
            } finally {
              if (n) throw o;
            }
          }
          (this.contents = this.element.querySelectorAll(
            '[data-type="content"]'
          )),
            this.handlePanelsClick();
        }
      }
      return (
        n(e, [
          {
            key: "handlePanelsClick",
            value: function () {
              const e = this;
              if (this.panels && !(this.panels.length <= 0)) {
                let t = !0,
                  r = !1,
                  n = void 0;
                try {
                  for (
                    var o,
                      i = function () {
                        const t = o.value;
                        t.addEventListener("click", function (r) {
                          let n = !1,
                            o = !0,
                            i = !1,
                            a = void 0;
                          try {
                            for (
                              var l, u = e.contents[Symbol.iterator]();
                              !(o = (l = u.next()).done);
                              o = !0
                            ) {
                              const s = l.value;
                              s.classList.toggle(t.element.openedClass),
                                s.classList.contains(t.element.openedClass) &&
                                  (n = !0);
                            }
                          } catch (e) {
                            (i = !0), (a = e);
                          } finally {
                            try {
                              !o && u.return && u.return();
                            } finally {
                              if (i) throw a;
                            }
                          }
                          n
                            ? (t.classList.add(t.element.openedClass),
                              t.element.classList.add(t.element.openedClass))
                            : (t.classList.remove(t.element.openedClass),
                              t.element.classList.remove(
                                t.element.openedClass
                              ));
                        });
                      },
                      a = this.panels[Symbol.iterator]();
                    !(t = (o = a.next()).done);
                    t = !0
                  )
                    i();
                } catch (e) {
                  (r = !0), (n = e);
                } finally {
                  try {
                    !t && a.return && a.return();
                  } finally {
                    if (r) throw n;
                  }
                }
                document.addEventListener("click", function (t) {
                  e.element.contains(t.target) || e.close();
                });
              }
            },
          },
          {
            key: "setPanelsContent",
            value: function (e) {
              let t = !0,
                r = !1,
                n = void 0;
              try {
                for (
                  var o, i = this.panels[Symbol.iterator]();
                  !(t = (o = i.next()).done);
                  t = !0
                ) {
                  o.value.innerHTML = e;
                }
              } catch (e) {
                (r = !0), (n = e);
              } finally {
                try {
                  !t && i.return && i.return();
                } finally {
                  if (r) throw n;
                }
              }
            },
          },
          {
            key: "restorePanelsContent",
            value: function () {
              let e = !0,
                t = !1,
                r = void 0;
              try {
                for (
                  var n, o = this.panels[Symbol.iterator]();
                  !(e = (n = o.next()).done);
                  e = !0
                ) {
                  const i = n.value;
                  i.initialContent && (i.innerHTML = i.initialContent);
                }
              } catch (e) {
                (t = !0), (r = e);
              } finally {
                try {
                  !e && o.return && o.return();
                } finally {
                  if (t) throw r;
                }
              }
            },
          },
          {
            key: "close",
            value: function () {
              let e = !0,
                t = !1,
                r = void 0;
              try {
                for (
                  var n, o = this.contents[Symbol.iterator]();
                  !(e = (n = o.next()).done);
                  e = !0
                ) {
                  n.value.classList.remove(this.panels[0].element.openedClass);
                }
              } catch (e) {
                (t = !0), (r = e);
              } finally {
                try {
                  !e && o.return && o.return();
                } finally {
                  if (t) throw r;
                }
              }
              let i = !0,
                a = !1,
                l = void 0;
              try {
                for (
                  var u, s = this.panels[Symbol.iterator]();
                  !(i = (u = s.next()).done);
                  i = !0
                ) {
                  const c = u.value;
                  c.classList.remove(c.element.openedClass),
                    c.element.classList.remove(c.element.openedClass);
                }
              } catch (e) {
                (a = !0), (l = e);
              } finally {
                try {
                  !i && s.return && s.return();
                } finally {
                  if (a) throw l;
                }
              }
            },
          },
        ]),
        e
      );
    })();
    t.default = o;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
      function e(e, t) {
        for (let r = 0; r < t.length; r++) {
          const n = t[r];
          (n.enumerable = n.enumerable || !1),
            (n.configurable = !0),
            "value" in n && (n.writable = !0),
            Object.defineProperty(e, n.key, n);
        }
      }
      return function (t, r, n) {
        return r && e(t.prototype, r), n && e(t, n), t;
      };
    })();
    t.default = function (e) {
      return (function (t) {
        function r(e, t) {
          const n =
              arguments.length > 2 && void 0 !== arguments[2]
                ? arguments[2]
                : [],
            o =
              arguments.length > 3 && void 0 !== arguments[3]
                ? arguments[3]
                : null;
          !(function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, r);
          const i = (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (r.__proto__ || Object.getPrototypeOf(r)).call(this, e, t, n, o)
          );
          return (
            (i.group = e), (i.name = t), (i.checkboxes = []), (i.radios = []), i
          );
        }
        return (
          (function (e, t) {
            if ("function" != typeof t && null !== t)
              throw new TypeError(
                "Super expression must either be null or a function, not " +
                  typeof t
              );
            (e.prototype = Object.create(t && t.prototype, {
              constructor: {
                value: e,
                enumerable: !1,
                writable: !0,
                configurable: !0,
              },
            })),
              t &&
                (Object.setPrototypeOf
                  ? Object.setPrototypeOf(e, t)
                  : (e.__proto__ = t));
          })(r, e),
          n(r, [
            {
              key: "addControl",
              value: function (e) {
                const t = this,
                  n = (function e(t, r, n) {
                    null === t && (t = Function.prototype);
                    const o = Object.getOwnPropertyDescriptor(t, r);
                    if (void 0 === o) {
                      const i = Object.getPrototypeOf(t);
                      return null === i ? void 0 : e(i, r, n);
                    }
                    if ("value" in o) return o.value;
                    const a = o.get;
                    return void 0 !== a ? a.call(n) : void 0;
                  })(
                    r.prototype.__proto__ || Object.getPrototypeOf(r.prototype),
                    "addControl",
                    this
                  ).call(this, e);
                if (
                  ((n.selected =
                    "true" === n.element.getAttribute("data-selected")),
                  (n.mode = n.element.getAttribute("data-mode") || "radio"),
                  n.id)
                ) {
                  const o = this.deepLinkParams.find(function (e) {
                    return e.key === n.id;
                  });
                  o && (n.selected = "1" === o.value);
                }
                "radio" === n.mode &&
                  (this.radios.push(n), this.handleRadios()),
                  "checkbox" === n.mode &&
                    (this.checkboxes.push(n), this.handleCheckboxes()),
                  n.element.addEventListener("click", function (e) {
                    if (
                      (e.preventDefault(),
                      "checkbox" === n.mode &&
                        ((n.selected = !n.selected),
                        t.checkboxes.forEach(function (e) {
                          e.isEqualTo(n) && (e.selected = n.selected);
                        }),
                        t.handleCheckboxes()),
                      "radio" === n.mode)
                    ) {
                      let r = !0,
                        o = !1,
                        i = void 0;
                      try {
                        for (
                          var a, l = t.radios[Symbol.iterator]();
                          !(r = (a = l.next()).done);
                          r = !0
                        )
                          a.value.selected = !1;
                      } catch (e) {
                        (o = !0), (i = e);
                      } finally {
                        try {
                          !r && l.return && l.return();
                        } finally {
                          if (o) throw i;
                        }
                      }
                      (n.selected = !0), t.handleRadios();
                    }
                    window.jplist && window.jplist.refresh(t.group, n);
                  });
              },
            },
            {
              key: "handleCheckboxes",
              value: function () {
                let e = !0,
                  t = !1,
                  r = void 0;
                try {
                  for (
                    var n, o = this.checkboxes[Symbol.iterator]();
                    !(e = (n = o.next()).done);
                    e = !0
                  ) {
                    const i = n.value;
                    i.selected
                      ? i.element.classList.add("jplist-selected")
                      : i.element.classList.remove("jplist-selected"),
                      (i.element.checked = i.selected);
                  }
                } catch (e) {
                  (t = !0), (r = e);
                } finally {
                  try {
                    !e && o.return && o.return();
                  } finally {
                    if (t) throw r;
                  }
                }
              },
            },
            {
              key: "getLastSelectedRadio",
              value: function () {
                let e = null,
                  t = !0,
                  r = !1,
                  n = void 0;
                try {
                  for (
                    var o, i = this.radios[Symbol.iterator]();
                    !(t = (o = i.next()).done);
                    t = !0
                  ) {
                    const a = o.value;
                    a.selected && (e = a);
                  }
                } catch (e) {
                  (r = !0), (n = e);
                } finally {
                  try {
                    !t && i.return && i.return();
                  } finally {
                    if (r) throw n;
                  }
                }
                return e;
              },
            },
            {
              key: "handleRadios",
              value: function () {
                if (this.radios.length > 0) {
                  let e = this.getLastSelectedRadio(),
                    t = !0,
                    r = !1,
                    n = void 0;
                  try {
                    for (
                      var o, i = this.radios[Symbol.iterator]();
                      !(t = (o = i.next()).done);
                      t = !0
                    ) {
                      const a = o.value;
                      (a.selected = !1),
                        a.element.classList.remove("jplist-selected");
                    }
                  } catch (e) {
                    (r = !0), (n = e);
                  } finally {
                    try {
                      !t && i.return && i.return();
                    } finally {
                      if (r) throw n;
                    }
                  }
                  e &&
                    this.radios.forEach(function (t) {
                      t.isEqualTo(e) &&
                        ((t.selected = !0),
                        (t.element.checked = !0),
                        t.element.classList.add("jplist-selected"));
                    });
                }
              },
            },
            {
              key: "getDeepLink",
              value: function () {
                const e = this.checkboxes
                    .map(function (e) {
                      return e.id
                        ? e.selected
                          ? e.id + "=1"
                          : e.id + "=0"
                        : "";
                    })
                    .filter(function (e) {
                      return "" !== e;
                    }),
                  t = this.radios
                    .map(function (e) {
                      return e.id && e.selected ? e.id + "=1" : "";
                    })
                    .filter(function (e) {
                      return "" !== e;
                    }),
                  r = e.concat(t);
                return Array.from(new Set(r)).join("&");
              },
            },
          ]),
          r
        );
      })();
    };
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    let n,
      o = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      i = r(1),
      a = (n = i) && n.__esModule ? n : { default: n };
    const l = (function (e) {
      function t(e) {
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, t);
        const r = (function (e, t) {
          if (!e)
            throw new ReferenceError(
              "this hasn't been initialised - super() hasn't been called"
            );
          return !t || ("object" != typeof t && "function" != typeof t) ? e : t;
        })(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this, e));
        if (e) {
          (r.path = (e.getAttribute("data-path") || "").trim()),
            (r.dataType = (e.getAttribute("data-type") || "text")
              .trim()
              .toLowerCase()),
            (r.order = (e.getAttribute("data-order") || "asc")
              .trim()
              .toLowerCase()),
            (r.regex = e.getAttribute("data-regex") || ""),
            (r.dateTimeFormat = (e.getAttribute("data-date-format") || "")
              .trim()
              .toLowerCase()),
            (r.multipleSortsNumber = r.getMultipleSortsNumber(e));
          for (let n = 1; n <= r.multipleSortsNumber; n++)
            (r["path" + n] = (e.getAttribute("data-path-" + n) || "").trim()),
              (r["dataType" + n] = (e.getAttribute("data-type-" + n) || "text")
                .trim()
                .toLowerCase()),
              (r["order" + n] = (e.getAttribute("data-order-" + n) || "asc")
                .trim()
                .toLowerCase()),
              (r["regex" + n] = e.getAttribute("data-regex-" + n) || ""),
              (r["dateTimeFormat" + n] = (
                e.getAttribute("data-date-format-" + n) || ""
              )
                .trim()
                .toLowerCase());
        }
        return r;
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, a.default),
        o(t, [
          {
            key: "getMultipleSortsNumber",
            value: function (e) {
              let t = 0,
                r = !0,
                n = !1,
                o = void 0;
              try {
                for (
                  var i, a = e.attributes[Symbol.iterator]();
                  !(r = (i = a.next()).done);
                  r = !0
                )
                  for (
                    let l = i.value, u = null, s = /^data-path-([0-9]+)$/g;
                    (u = s.exec(l.nodeName));

                  ) {
                    const c = Number(u[1]);
                    Number.isInteger(c) && t++;
                  }
              } catch (e) {
                (n = !0), (o = e);
              } finally {
                try {
                  !r && a.return && a.return();
                } finally {
                  if (n) throw o;
                }
              }
              return t;
            },
          },
          {
            key: "getSortOptions",
            value: function () {
              const e = [];
              if (this.path) {
                e.push({
                  path: this.path,
                  dataType: this.dataType,
                  order: this.order,
                  ignoreRegex: this.ignoreRegex,
                  dateTimeFormat: this.dateTimeFormat,
                });
                for (let t = 1; t <= this.multipleSortsNumber; t++)
                  e.push({
                    path: this["path" + t],
                    dataType: this["dataType" + t],
                    order: this["order" + t],
                    ignoreRegex: this["ignoreRegex" + t],
                    dateTimeFormat: this["dateTimeFormat" + t],
                  });
              }
              return e;
            },
          },
          {
            key: "isEqualTo",
            value: function (e) {
              for (
                var t = !0,
                  r = ["path", "dataType", "order", "regex", "dateTimeFormat"],
                  n = 0;
                n < r.length;
                n++
              )
                t = t && this[r[n]] === e[r[n]];
              t = t && this.multipleSortsNumber === e.multipleSortsNumber;
              for (let o = 1; o <= this.multipleSortsNumber; o++)
                for (let i = 0; i < r.length; i++)
                  t = t && this[r[i] + o] === e[r[i] + o];
              return t;
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    let n,
      o = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      i = r(1),
      a = (n = i) && n.__esModule ? n : { default: n };
    const l = (function (e) {
      function t(e) {
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, t);
        const r = (function (e, t) {
          if (!e)
            throw new ReferenceError(
              "this hasn't been initialised - super() hasn't been called"
            );
          return !t || ("object" != typeof t && "function" != typeof t) ? e : t;
        })(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this, e));
        if (e) {
          r.path = (e.getAttribute("data-path") || "").trim();
          const n = e.getAttribute("data-from");
          (r.from = null === n ? -1 / 0 : Number(n)),
            isNaN(r.from) && (r.from = -1 / 0);
          const o = e.getAttribute("data-to");
          (r.to = null === o ? 1 / 0 : Number(o)),
            isNaN(r.to) && (r.to = 1 / 0);
          const i = e.getAttribute("data-min");
          (r.min = null === i ? r.from : Number(i)),
            isNaN(r.min) && (r.min = r.from);
          const a = e.getAttribute("data-max");
          (r.max = null === a ? r.to : Number(a)),
            isNaN(r.max) && (r.max = r.to),
            (r.or = e.getAttribute("data-or") || null);
        }
        return r;
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, a.default),
        o(t, [
          {
            key: "getRangeFilterOptions",
            value: function () {
              return {
                path: this.path,
                min: this.min,
                from: this.from,
                to: this.to,
                max: this.max,
                or: this.or,
              };
            },
          },
          {
            key: "isEqualTo",
            value: function (e) {
              return (
                this.path === e.path &&
                this.from === e.from &&
                this.to === e.to &&
                this.min === e.min &&
                this.max === e.max
              );
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = a(r(0)),
      i = a(r(9));
    function a(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const l = (function (e) {
      function t() {
        return (
          (function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
          (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (t.__proto__ || Object.getPrototypeOf(t)).apply(this, arguments)
          )
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, o.default),
        n(t, [
          {
            key: "addControl",
            value: function (e) {
              if (e.name !== this.name || e.group !== this.group) return null;
              const t = new i.default(e.element);
              return this.controls.push(t), t;
            },
          },
          {
            key: "getRangeFilterOptions",
            value: function () {
              let e = [],
                t = !0,
                r = !1,
                n = void 0;
              try {
                for (
                  var o, i = this.controls[Symbol.iterator]();
                  !(t = (o = i.next()).done);
                  t = !0
                ) {
                  const a = o.value;
                  e = e.concat(a.getRangeFilterOptions());
                }
              } catch (e) {
                (r = !0), (n = e);
              } finally {
                try {
                  !t && i.return && i.return();
                } finally {
                  if (r) throw n;
                }
              }
              return e;
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
      function e(e, t) {
        for (let r = 0; r < t.length; r++) {
          const n = t[r];
          (n.enumerable = n.enumerable || !1),
            (n.configurable = !0),
            "value" in n && (n.writable = !0),
            Object.defineProperty(e, n.key, n);
        }
      }
      return function (t, r, n) {
        return r && e(t.prototype, r), n && e(t, n), t;
      };
    })();
    t.default = function (e) {
      return (function (t) {
        function r(e, t) {
          const n =
              arguments.length > 2 && void 0 !== arguments[2]
                ? arguments[2]
                : [],
            o =
              arguments.length > 3 && void 0 !== arguments[3]
                ? arguments[3]
                : null;
          !(function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, r);
          const i = (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (r.__proto__ || Object.getPrototypeOf(r)).call(this, e, t, n, o)
          );
          return (i.group = e), (i.name = t), (i.radios = []), i;
        }
        return (
          (function (e, t) {
            if ("function" != typeof t && null !== t)
              throw new TypeError(
                "Super expression must either be null or a function, not " +
                  typeof t
              );
            (e.prototype = Object.create(t && t.prototype, {
              constructor: {
                value: e,
                enumerable: !1,
                writable: !0,
                configurable: !0,
              },
            })),
              t &&
                (Object.setPrototypeOf
                  ? Object.setPrototypeOf(e, t)
                  : (e.__proto__ = t));
          })(r, e),
          n(r, [
            {
              key: "addControl",
              value: function (e) {
                const t = this,
                  n = (function e(t, r, n) {
                    null === t && (t = Function.prototype);
                    const o = Object.getOwnPropertyDescriptor(t, r);
                    if (void 0 === o) {
                      const i = Object.getPrototypeOf(t);
                      return null === i ? void 0 : e(i, r, n);
                    }
                    if ("value" in o) return o.value;
                    const a = o.get;
                    return void 0 !== a ? a.call(n) : void 0;
                  })(
                    r.prototype.__proto__ || Object.getPrototypeOf(r.prototype),
                    "addControl",
                    this
                  ).call(this, e);
                if (((n.selected = n.element.checked), n.id)) {
                  const o = this.deepLinkParams.find(function (e) {
                    return e.key === n.id;
                  });
                  o && (n.selected = "1" === o.value);
                }
                this.radios.push(n),
                  this.handleRadios(),
                  n.element.addEventListener("change", function (e) {
                    e.preventDefault();
                    let r = !0,
                      o = !1,
                      i = void 0;
                    try {
                      for (
                        var a, l = t.radios[Symbol.iterator]();
                        !(r = (a = l.next()).done);
                        r = !0
                      )
                        a.value.selected = !1;
                    } catch (e) {
                      (o = !0), (i = e);
                    } finally {
                      try {
                        !r && l.return && l.return();
                      } finally {
                        if (o) throw i;
                      }
                    }
                    (n.selected = !0),
                      t.handleRadios(),
                      window.jplist && window.jplist.refresh(t.group, n);
                  });
              },
            },
            {
              key: "getLastSelectedRadio",
              value: function () {
                let e = null,
                  t = !0,
                  r = !1,
                  n = void 0;
                try {
                  for (
                    var o, i = this.radios[Symbol.iterator]();
                    !(t = (o = i.next()).done);
                    t = !0
                  ) {
                    const a = o.value;
                    a.selected && (e = a);
                  }
                } catch (e) {
                  (r = !0), (n = e);
                } finally {
                  try {
                    !t && i.return && i.return();
                  } finally {
                    if (r) throw n;
                  }
                }
                return e;
              },
            },
            {
              key: "handleRadios",
              value: function () {
                if (this.radios.length > 0) {
                  let e = this.getLastSelectedRadio(),
                    t = !0,
                    r = !1,
                    n = void 0;
                  try {
                    for (
                      var o, i = this.radios[Symbol.iterator]();
                      !(t = (o = i.next()).done);
                      t = !0
                    ) {
                      const a = o.value;
                      (a.selected = !1),
                        a.element.classList.remove("jplist-selected");
                    }
                  } catch (e) {
                    (r = !0), (n = e);
                  } finally {
                    try {
                      !t && i.return && i.return();
                    } finally {
                      if (r) throw n;
                    }
                  }
                  e &&
                    this.radios.forEach(function (t) {
                      t.isEqualTo(e) &&
                        ((t.selected = !0),
                        (t.element.checked = !0),
                        t.element.classList.add("jplist-selected"));
                    });
                }
              },
            },
            {
              key: "getDeepLink",
              value: function () {
                const e = this.radios
                  .map(function (e) {
                    return e.id && e.selected ? e.id + "=1" : "";
                  })
                  .filter(function (e) {
                    return "" !== e;
                  });
                return Array.from(new Set(e)).join("&");
              },
            },
          ]),
          r
        );
      })();
    };
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
      function e(e, t) {
        for (let r = 0; r < t.length; r++) {
          const n = t[r];
          (n.enumerable = n.enumerable || !1),
            (n.configurable = !0),
            "value" in n && (n.writable = !0),
            Object.defineProperty(e, n.key, n);
        }
      }
      return function (t, r, n) {
        return r && e(t.prototype, r), n && e(t, n), t;
      };
    })();
    t.default = function (e) {
      return (function (t) {
        function r(e, t) {
          const n =
              arguments.length > 2 && void 0 !== arguments[2]
                ? arguments[2]
                : [],
            o =
              arguments.length > 3 && void 0 !== arguments[3]
                ? arguments[3]
                : null;
          !(function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, r);
          const i = (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (r.__proto__ || Object.getPrototypeOf(r)).call(this, e, t, n, o)
          );
          return (i.group = e), (i.name = t), (i.checkboxes = []), i;
        }
        return (
          (function (e, t) {
            if ("function" != typeof t && null !== t)
              throw new TypeError(
                "Super expression must either be null or a function, not " +
                  typeof t
              );
            (e.prototype = Object.create(t && t.prototype, {
              constructor: {
                value: e,
                enumerable: !1,
                writable: !0,
                configurable: !0,
              },
            })),
              t &&
                (Object.setPrototypeOf
                  ? Object.setPrototypeOf(e, t)
                  : (e.__proto__ = t));
          })(r, e),
          n(r, [
            {
              key: "addControl",
              value: function (e) {
                const t = this,
                  n = (function e(t, r, n) {
                    null === t && (t = Function.prototype);
                    const o = Object.getOwnPropertyDescriptor(t, r);
                    if (void 0 === o) {
                      const i = Object.getPrototypeOf(t);
                      return null === i ? void 0 : e(i, r, n);
                    }
                    if ("value" in o) return o.value;
                    const a = o.get;
                    return void 0 !== a ? a.call(n) : void 0;
                  })(
                    r.prototype.__proto__ || Object.getPrototypeOf(r.prototype),
                    "addControl",
                    this
                  ).call(this, e);
                if (((n.selected = n.element.checked), n.id)) {
                  const o = this.deepLinkParams.find(function (e) {
                    return e.key === n.id;
                  });
                  o && (n.selected = "1" === o.value);
                }
                this.checkboxes.push(n),
                  this.handleCheckboxes(),
                  n.element.addEventListener("change", function (e) {
                    e.preventDefault(),
                      (n.selected = !n.selected),
                      t.checkboxes.forEach(function (e) {
                        e.isEqualTo(n) && (e.selected = n.selected);
                      }),
                      t.handleCheckboxes(),
                      window.jplist && window.jplist.refresh(t.group, n);
                  });
              },
            },
            {
              key: "handleCheckboxes",
              value: function () {
                let e = !0,
                  t = !1,
                  r = void 0;
                try {
                  for (
                    var n, o = this.checkboxes[Symbol.iterator]();
                    !(e = (n = o.next()).done);
                    e = !0
                  ) {
                    const i = n.value;
                    i.selected
                      ? i.element.classList.add("jplist-selected")
                      : i.element.classList.remove("jplist-selected"),
                      (i.element.checked = i.selected);
                  }
                } catch (e) {
                  (t = !0), (r = e);
                } finally {
                  try {
                    !e && o.return && o.return();
                  } finally {
                    if (t) throw r;
                  }
                }
              },
            },
            {
              key: "getDeepLink",
              value: function () {
                const e = this.checkboxes
                  .map(function (e) {
                    return e.id ? (e.selected ? e.id + "=1" : e.id + "=0") : "";
                  })
                  .filter(function (e) {
                    return "" !== e;
                  });
                return Array.from(new Set(e)).join("&");
              },
            },
          ]),
          r
        );
      })();
    };
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    let n,
      o = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      i = r(1),
      a = (n = i) && n.__esModule ? n : { default: n };
    const l = (function (e) {
      function t(e) {
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, t);
        const r = (function (e, t) {
          if (!e)
            throw new ReferenceError(
              "this hasn't been initialised - super() hasn't been called"
            );
          return !t || ("object" != typeof t && "function" != typeof t) ? e : t;
        })(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this, e));
        return (
          e &&
            ((r.path = (e.getAttribute("data-path") || "").trim()),
            (r.initialText = e.getAttribute("data-text") || e.value || ""),
            (r._text = (e.getAttribute("data-text") || e.value || "").trim()),
            (r.mode = (e.getAttribute("data-mode") || "contains").trim()),
            (r.regex = e.getAttribute("data-regex") || ""),
            (r.or = e.getAttribute("data-or") || null)),
          r
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, a.default),
        o(t, [
          {
            key: "getTextFilterOptions",
            value: function () {
              return {
                path: this.path,
                text: this.text,
                mode: this.mode,
                ignoreRegex: this.regex,
                or: this.or,
              };
            },
          },
          {
            key: "isEqualTo",
            value: function (e) {
              let t =
                  !(arguments.length > 1 && void 0 !== arguments[1]) ||
                  arguments[1],
                r =
                  this.path === e.path &&
                  this.mode === e.mode &&
                  this.regex === e.regex;
              return t && (r = r && this.text === e.text), r;
            },
          },
          {
            key: "text",
            set: function (e) {
              (this.initialText = e || ""), (this._text = (e || "").trim());
            },
            get: function () {
              return this._text;
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
      function e(e, t) {
        for (let r = 0; r < t.length; r++) {
          const n = t[r];
          (n.enumerable = n.enumerable || !1),
            (n.configurable = !0),
            "value" in n && (n.writable = !0),
            Object.defineProperty(e, n.key, n);
        }
      }
      return function (t, r, n) {
        return r && e(t.prototype, r), n && e(t, n), t;
      };
    })();
    const o = (function () {
      function e() {
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, e);
      }
      return (
        n(e, null, [
          {
            key: "textFilter",
            value: function (e, t) {
              const r =
                  arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : "",
                n =
                  arguments.length > 3 && void 0 !== arguments[3]
                    ? arguments[3]
                    : "contains",
                o =
                  arguments.length > 4 && void 0 !== arguments[4]
                    ? arguments[4]
                    : "",
                i = [];
              if (!e) return [];
              if ("default" === r) return e;
              let a = t.replace(new RegExp(o, "ig"), "").toLowerCase().trim(),
                l = !0,
                u = !1,
                s = void 0;
              try {
                for (
                  var c, f = e[Symbol.iterator]();
                  !(l = (c = f.next()).done);
                  l = !0
                ) {
                  const d = c.value,
                    p = r ? d.querySelectorAll(r) : [d];
                  if (p) {
                    let h = !1,
                      v = !0,
                      y = !1,
                      b = void 0;
                    try {
                      for (
                        var m, g = p[Symbol.iterator]();
                        !(v = (m = g.next()).done);
                        v = !0
                      ) {
                        const w = m.value.textContent
                          .replace(new RegExp(o, "ig"), "")
                          .toLowerCase()
                          .trim();
                        switch (n) {
                          case "startsWith":
                            w.startsWith(a) && (h = !0);
                            break;
                          case "endsWith":
                            w.endsWith(a) && (h = !0);
                            break;
                          case "equal":
                            w === a && (h = !0);
                            break;
                          default:
                            -1 !== w.indexOf(a) && (h = !0);
                        }
                        if (h) break;
                      }
                    } catch (e) {
                      (y = !0), (b = e);
                    } finally {
                      try {
                        !v && g.return && g.return();
                      } finally {
                        if (y) throw b;
                      }
                    }
                    h && i.push(d);
                  }
                }
              } catch (e) {
                (u = !0), (s = e);
              } finally {
                try {
                  !l && f.return && f.return();
                } finally {
                  if (u) throw s;
                }
              }
              return i;
            },
          },
          {
            key: "pathFilter",
            value: function (e) {
              const t =
                  arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : "",
                r =
                  arguments.length > 2 &&
                  void 0 !== arguments[2] &&
                  arguments[2],
                n = [];
              if (!e) return [];
              if ("default" === t || !t) return e;
              let o = !0,
                i = !1,
                a = void 0;
              try {
                for (
                  var l, u = e[Symbol.iterator]();
                  !(o = (l = u.next()).done);
                  o = !0
                ) {
                  const s = l.value,
                    c = s.querySelector(t);
                  ((c && !r) || (!c && r)) && n.push(s);
                }
              } catch (e) {
                (i = !0), (a = e);
              } finally {
                try {
                  !o && u.return && u.return();
                } finally {
                  if (i) throw a;
                }
              }
              return n;
            },
          },
          {
            key: "isNumeric",
            value: function (e) {
              return !isNaN(parseFloat(e)) && isFinite(e);
            },
          },
          {
            key: "rangeFilter",
            value: function (t) {
              let r =
                  arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : "",
                n = arguments[2],
                o = arguments[3],
                i =
                  arguments.length > 4 && void 0 !== arguments[4]
                    ? arguments[4]
                    : n,
                a =
                  arguments.length > 5 && void 0 !== arguments[5]
                    ? arguments[5]
                    : o,
                l = [];
              if (!t) return [];
              if ("default" === r) return t;
              (n = Math.max(n, i)), (o = Math.min(o, a));
              let u = !0,
                s = !1,
                c = void 0;
              try {
                for (
                  var f, d = t[Symbol.iterator]();
                  !(u = (f = d.next()).done);
                  u = !0
                ) {
                  const p = f.value,
                    h = r ? p.querySelectorAll(r) : [p];
                  if (h) {
                    let v = [],
                      y = !0,
                      b = !1,
                      m = void 0;
                    try {
                      for (
                        var g, w = h[Symbol.iterator]();
                        !(y = (g = w.next()).done);
                        y = !0
                      ) {
                        const O = g.value,
                          _ = Number(
                            O.textContent.trim().replace(/[^-0-9.]+/g, "")
                          );
                        isNaN(_) || v.push(_);
                      }
                    } catch (e) {
                      (b = !0), (m = e);
                    } finally {
                      try {
                        !y && w.return && w.return();
                      } finally {
                        if (b) throw m;
                      }
                    }
                    if (v.length > 0) {
                      let j = Math.max.apply(Math, v),
                        P = Math.min.apply(Math, v),
                        k = !0;
                      e.isNumeric(n) && n > P && (k = !1),
                        e.isNumeric(o) && j > o && (k = !1),
                        k && l.push(p);
                    }
                  }
                }
              } catch (e) {
                (s = !0), (c = e);
              } finally {
                try {
                  !u && d.return && d.return();
                } finally {
                  if (s) throw c;
                }
              }
              return l;
            },
          },
        ]),
        e
      );
    })();
    t.default = o;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = s(r(0)),
      i = s(r(14)),
      a = s(r(4)),
      l = s(r(13)),
      u = s(r(9));
    function s(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const c = (function (e) {
      function t() {
        return (
          (function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
          (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (t.__proto__ || Object.getPrototypeOf(t)).apply(this, arguments)
          )
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, o.default),
        n(
          t,
          [
            {
              key: "render",
              value: function (e) {
                let t = !0,
                  r = !1,
                  n = void 0;
                try {
                  for (
                    var o, i = this.controls[Symbol.iterator]();
                    !(t = (o = i.next()).done);
                    t = !0
                  ) {
                    const a = o.value;
                    a.element.innerHTML = a.format.replace("{count}", e);
                  }
                } catch (e) {
                  (r = !0), (n = e);
                } finally {
                  try {
                    !t && i.return && i.return();
                  } finally {
                    if (r) throw n;
                  }
                }
              },
            },
            {
              key: "addControl",
              value: function (e) {
                const r = this;
                (function e(t, r, n) {
                  null === t && (t = Function.prototype);
                  const o = Object.getOwnPropertyDescriptor(t, r);
                  if (void 0 === o) {
                    const i = Object.getPrototypeOf(t);
                    return null === i ? void 0 : e(i, r, n);
                  }
                  if ("value" in o) return o.value;
                  const a = o.get;
                  return void 0 !== a ? a.call(n) : void 0;
                })(
                  t.prototype.__proto__ || Object.getPrototypeOf(t.prototype),
                  "addControl",
                  this
                ).call(this, e),
                  (e.filterType =
                    e.element.getAttribute("data-filter-type") || "path"),
                  (e.format =
                    e.element.getAttribute("data-format") || "{count}"),
                  (e.mode = e.element.getAttribute("data-mode") || "dynamic");
                let n = null;
                switch (e.filterType) {
                  case "text":
                    n = new l.default(e.element);
                    break;
                  case "path":
                    n = new a.default(e.element);
                    break;
                  case "range":
                    n = new u.default(e.element);
                }
                e.element.addEventListener(
                  "jplist.state",
                  function (o) {
                    if (n && o.jplistState) {
                      let i = 0;
                      if (
                        "static" === e.mode &&
                        o.jplistState.groups &&
                        o.jplistState.groups.has(e.group)
                      ) {
                        const a = o.jplistState.groups.get(e.group);
                        i = t.getStaticCounterValue(n, e.filterType, a);
                      }
                      "dynamic" === e.mode &&
                        o.jplistState.filtered &&
                        o.jplistState.filtered.length > 0 &&
                        (i = t.getDynamicCounterValue(
                          n,
                          e.filterType,
                          o.jplistState.filtered
                        )),
                        r.render(i);
                    }
                  },
                  !1
                );
              },
            },
          ],
          [
            {
              key: "getDynamicCounterValue",
              value: function (e, r, n) {
                return (n = t.getFilteredItems(e, r, n)).length;
              },
            },
            {
              key: "getStaticCounterValue",
              value: function (e, r, n) {
                let o = 0,
                  i = !0,
                  a = !1,
                  l = void 0;
                try {
                  for (
                    var u, s = n[Symbol.iterator]();
                    !(i = (u = s.next()).done);
                    i = !0
                  ) {
                    let c = u.value.items;
                    o += (c = t.getFilteredItems(e, r, c)).length;
                  }
                } catch (e) {
                  (a = !0), (l = e);
                } finally {
                  try {
                    !i && s.return && s.return();
                  } finally {
                    if (a) throw l;
                  }
                }
                return o;
              },
            },
            {
              key: "getFilteredItems",
              value: function (e, t, r) {
                switch (t) {
                  case "text":
                    r = i.default.textFilter(
                      r,
                      e.text,
                      e.path,
                      e.mode,
                      e.regex
                    );
                    break;
                  case "path":
                    r = i.default.pathFilter(r, e.path, e.isInverted);
                    break;
                  case "range":
                    r = i.default.rangeFilter(
                      r,
                      e.path,
                      e.from,
                      e.to,
                      e.min,
                      e.max
                    );
                }
                return r;
              },
            },
          ]
        ),
        t
      );
    })();
    t.default = c;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    let n,
      o = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      i = r(0),
      a = (n = i) && n.__esModule ? n : { default: n };
    const l = (function (e) {
      function t() {
        return (
          (function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
          (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (t.__proto__ || Object.getPrototypeOf(t)).apply(this, arguments)
          )
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, a.default),
        o(t, [
          {
            key: "addControl",
            value: function (e) {
              const r = this;
              (function e(t, r, n) {
                null === t && (t = Function.prototype);
                const o = Object.getOwnPropertyDescriptor(t, r);
                if (void 0 === o) {
                  const i = Object.getPrototypeOf(t);
                  return null === i ? void 0 : e(i, r, n);
                }
                if ("value" in o) return o.value;
                const a = o.get;
                return void 0 !== a ? a.call(n) : void 0;
              })(
                t.prototype.__proto__ || Object.getPrototypeOf(t.prototype),
                "addControl",
                this
              ).call(this, e),
                e.element.addEventListener(
                  "click",
                  function (t) {
                    t.preventDefault(),
                      window.jplist && window.jplist.resetControls(r.group, e);
                  },
                  !1
                );
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    let n,
      o = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      i = r(0),
      a = (n = i) && n.__esModule ? n : { default: n };
    const l = (function (e) {
      function t(e, r) {
        const n =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : [],
          o =
            arguments.length > 3 && void 0 !== arguments[3]
              ? arguments[3]
              : null;
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, t);
        const i = (function (e, t) {
          if (!e)
            throw new ReferenceError(
              "this hasn't been initialised - super() hasn't been called"
            );
          return !t || ("object" != typeof t && "function" != typeof t) ? e : t;
        })(
          this,
          (t.__proto__ || Object.getPrototypeOf(t)).call(this, e, r, n, o)
        );
        return (
          (i.group = e),
          (i.name = r),
          (i.classNames = new Set()),
          (i.selectedClassName = ""),
          i
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, a.default),
        o(
          t,
          [
            {
              key: "addControl",
              value: function (e) {
                const r = this;
                if (
                  ((function e(t, r, n) {
                    null === t && (t = Function.prototype);
                    const o = Object.getOwnPropertyDescriptor(t, r);
                    if (void 0 === o) {
                      const i = Object.getPrototypeOf(t);
                      return null === i ? void 0 : e(i, r, n);
                    }
                    if ("value" in o) return o.value;
                    const a = o.get;
                    return void 0 !== a ? a.call(n) : void 0;
                  })(
                    t.prototype.__proto__ || Object.getPrototypeOf(t.prototype),
                    "addControl",
                    this
                  ).call(this, e),
                  (e.groupClassName =
                    e.element.getAttribute("data-class") || ""),
                  (e.selected =
                    "true" === e.element.getAttribute("data-selected")),
                  e.id)
                ) {
                  const n = this.deepLinkParams.find(function (t) {
                    return t.key === e.id;
                  });
                  n && (e.selected = "1" === n.value);
                }
                this.classNames.add(e.groupClassName),
                  e.element.addEventListener(
                    "click",
                    function (t) {
                      t.preventDefault(),
                        r.handleSelectedControls(e.groupClassName),
                        r.handleClasses(),
                        window.jplist && window.jplist.refresh(r.group, e);
                    },
                    !1
                  ),
                  this.handleClasses();
              },
            },
            {
              key: "handleClasses",
              value: function () {
                const e = document.querySelectorAll(
                  '[data-jplist-group="' + this.group + '"]'
                );
                this.resetAllGroups(e);
                const r = this.getLatestSelectedControl();
                r &&
                  (this.handleSelectedControls(r.groupClassName),
                  t.addClassToGroups(r.groupClassName, e));
              },
            },
            {
              key: "getLatestSelectedControl",
              value: function () {
                let e = null,
                  t = !0,
                  r = !1,
                  n = void 0;
                try {
                  for (
                    var o, i = this.controls[Symbol.iterator]();
                    !(t = (o = i.next()).done);
                    t = !0
                  ) {
                    const a = o.value;
                    a.selected && (e = a);
                  }
                } catch (e) {
                  (r = !0), (n = e);
                } finally {
                  try {
                    !t && i.return && i.return();
                  } finally {
                    if (r) throw n;
                  }
                }
                return (
                  !e && this.controls.length > 0 && (e = this.controls[0]), e
                );
              },
            },
            {
              key: "resetAllGroups",
              value: function (e) {
                let t = !0,
                  r = !1,
                  n = void 0;
                try {
                  for (
                    var o, i = e[Symbol.iterator]();
                    !(t = (o = i.next()).done);
                    t = !0
                  ) {
                    let a = o.value,
                      l = !0,
                      u = !1,
                      s = void 0;
                    try {
                      for (
                        var c, f = this.classNames[Symbol.iterator]();
                        !(l = (c = f.next()).done);
                        l = !0
                      ) {
                        const d = c.value;
                        a.classList.remove(d);
                      }
                    } catch (e) {
                      (u = !0), (s = e);
                    } finally {
                      try {
                        !l && f.return && f.return();
                      } finally {
                        if (u) throw s;
                      }
                    }
                  }
                } catch (e) {
                  (r = !0), (n = e);
                } finally {
                  try {
                    !t && i.return && i.return();
                  } finally {
                    if (r) throw n;
                  }
                }
              },
            },
            {
              key: "handleSelectedControls",
              value: function (e) {
                let t = !0,
                  r = !1,
                  n = void 0;
                try {
                  for (
                    var o, i = this.controls[Symbol.iterator]();
                    !(t = (o = i.next()).done);
                    t = !0
                  ) {
                    const a = o.value;
                    a.groupClassName === e
                      ? ((a.selected = !0),
                        a.element.classList.add("jplist-selected"))
                      : ((a.selected = !1),
                        a.element.classList.remove("jplist-selected"));
                  }
                } catch (e) {
                  (r = !0), (n = e);
                } finally {
                  try {
                    !t && i.return && i.return();
                  } finally {
                    if (r) throw n;
                  }
                }
              },
            },
            {
              key: "getDeepLink",
              value: function () {
                const e = this.controls
                  .map(function (e) {
                    return e.id ? (e.selected ? e.id + "=1" : e.id + "=0") : "";
                  })
                  .filter(function (e) {
                    return "" !== e;
                  });
                return Array.from(new Set(e)).join("&");
              },
            },
          ],
          [
            {
              key: "addClassToGroups",
              value: function (e, t) {
                let r = !0,
                  n = !1,
                  o = void 0;
                try {
                  for (
                    var i, a = t[Symbol.iterator]();
                    !(r = (i = a.next()).done);
                    r = !0
                  ) {
                    i.value.classList.add(e);
                  }
                } catch (e) {
                  (n = !0), (o = e);
                } finally {
                  try {
                    !r && a.return && a.return();
                  } finally {
                    if (n) throw o;
                  }
                }
              },
            },
          ]
        ),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    let n,
      o = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      i = r(0),
      a = (n = i) && n.__esModule ? n : { default: n };
    const l = (function (e) {
      function t() {
        return (
          (function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
          (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (t.__proto__ || Object.getPrototypeOf(t)).apply(this, arguments)
          )
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, a.default),
        o(t, [
          {
            key: "addControl",
            value: function (e) {
              (function e(t, r, n) {
                null === t && (t = Function.prototype);
                const o = Object.getOwnPropertyDescriptor(t, r);
                if (void 0 === o) {
                  const i = Object.getPrototypeOf(t);
                  return null === i ? void 0 : e(i, r, n);
                }
                if ("value" in o) return o.value;
                const a = o.get;
                return void 0 !== a ? a.call(n) : void 0;
              })(
                t.prototype.__proto__ || Object.getPrototypeOf(t.prototype),
                "addControl",
                this
              ).call(this, e),
                e.element.addEventListener(
                  "jplist.state",
                  function (t) {
                    if (t.jplistState) {
                      const r = Number(t.jplistState.itemsNumber) || 0;
                      e.element.style.display = 0 === r ? "" : "none";
                    }
                  },
                  !1
                );
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
      function e(e, t) {
        for (let r = 0; r < t.length; r++) {
          const n = t[r];
          (n.enumerable = n.enumerable || !1),
            (n.configurable = !0),
            "value" in n && (n.writable = !0),
            Object.defineProperty(e, n.key, n);
        }
      }
      return function (t, r, n) {
        return r && e(t.prototype, r), n && e(t, n), t;
      };
    })();
    r(48);
    const o = (function () {
      function e(t) {
        const r =
            arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
          n =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 0,
          o =
            arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 0,
          i =
            arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : 0,
          a =
            arguments.length > 5 && void 0 !== arguments[5] ? arguments[5] : 0,
          l =
            arguments.length > 6 && void 0 !== arguments[6]
              ? arguments[6]
              : function (e, t) {};
        if (
          ((function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, e),
          t)
        ) {
          if (
            ((this.element = t),
            this.element.classList.add("jplist-slider"),
            !this.element)
          )
            return;
          (this.isVertical = r),
            (this.callback = l),
            (this.min = n),
            (this.max = a),
            r && this.element.classList.add("jplist-slider-vertical"),
            (this.handler1 = document.createElement("span")),
            this.handler1.classList.add("jplist-slider-holder-1"),
            this.element.appendChild(this.handler1),
            (this.range = document.createElement("span")),
            this.range.classList.add("jplist-slider-range"),
            this.element.appendChild(this.range),
            (this.handler1.left = 0),
            (this.handler1.top = 0),
            (this.handler2 = document.createElement("span")),
            this.handler2.classList.add("jplist-slider-holder-2"),
            this.element.appendChild(this.handler2),
            (this.handler2.left = 0),
            (this.handler2.top = 0),
            (this.dragging = null),
            this.handler1.addEventListener("mousedown", this.start.bind(this)),
            this.handler2.addEventListener("mousedown", this.start.bind(this)),
            this.handler1.addEventListener("touchstart", this.start.bind(this)),
            this.handler2.addEventListener("touchstart", this.start.bind(this)),
            document.addEventListener("mousemove", this.render.bind(this)),
            document.addEventListener("touchmove", this.render.bind(this)),
            window.addEventListener("resize", this.resize.bind(this)),
            document.addEventListener("mouseup", this.stop.bind(this)),
            document.addEventListener("touchend", this.stop.bind(this)),
            document.body.addEventListener("mouseleave", this.stop.bind(this)),
            this.element.addEventListener("mousedown", this.jump.bind(this)),
            this.setValues(o, i);
        }
      }
      return (
        n(
          e,
          [
            {
              key: "setValues",
              value: function (e, t) {
                const r =
                  !(arguments.length > 2 && void 0 !== arguments[2]) ||
                  arguments[2];
                t < e && (t = e);
                const n = this.getInnerValue(e, this.min, this.max),
                  o = this.getInnerValue(t, this.min, this.max);
                this.update({ x: o, y: o }, this.handler2, r),
                  this.update({ x: n, y: n }, this.handler1, r);
              },
            },
            {
              key: "getPreviewValue",
              value: function (e, t, r) {
                const n = t,
                  o = r;
                return (
                  ((e - 0) /
                    (this.element.getBoundingClientRect()[
                      this.isVertical ? "height" : "width"
                    ] -
                      0)) *
                    (o - n) +
                  n
                );
              },
            },
            {
              key: "getInnerValue",
              value: function (e, t, r) {
                return (
                  ((e - t) / (r - t)) *
                    (this.element.getBoundingClientRect()[
                      this.isVertical ? "height" : "width"
                    ] -
                      0) +
                  0
                );
              },
            },
            {
              key: "jump",
              value: function (e) {
                e.preventDefault();
                const t = this.getHandlerPos(e);
                this.isVertical
                  ? (this.dragging =
                      Math.abs(t.y - this.handler1.top) <
                      Math.abs(t.y - this.handler2.top)
                        ? this.handler1
                        : this.handler2)
                  : (this.dragging =
                      Math.abs(t.x - this.handler1.left) <
                      Math.abs(t.x - this.handler2.left)
                        ? this.handler1
                        : this.handler2),
                  this.render(e);
              },
            },
            {
              key: "setZIndex",
              value: function () {
                const e =
                    (window.getComputedStyle &&
                      Number(
                        document.defaultView
                          .getComputedStyle(this.handler1, null)
                          .getPropertyValue("z-index")
                      )) ||
                    200,
                  t =
                    (window.getComputedStyle &&
                      Number(
                        document.defaultView
                          .getComputedStyle(this.handler2, null)
                          .getPropertyValue("z-index")
                      )) ||
                    200;
                if (e === t) this.dragging.style["z-index"] = e + 1;
                else {
                  const r = Math.max(e, t),
                    n = Math.min(e, t);
                  (this.handler1.style["z-index"] = n),
                    (this.handler2.style["z-index"] = n),
                    (this.dragging.style["z-index"] = r);
                }
              },
            },
            {
              key: "start",
              value: function (e) {
                e.preventDefault(),
                  e.stopPropagation(),
                  (this.dragging = e.target),
                  this.setZIndex(),
                  this.render();
              },
            },
            {
              key: "stop",
              value: function (e) {
                this.dragging = null;
              },
            },
            {
              key: "resize",
              value: function (e) {
                this.handler1 &&
                  this.handler2 &&
                  this.setValues(this.handler1.value, this.handler2.value);
              },
            },
            {
              key: "render",
              value: function (e) {
                e &&
                  this.dragging &&
                  this.update(this.getHandlerPos(e), this.dragging);
              },
            },
            {
              key: "update",
              value: function (e, t) {
                const r =
                  !(arguments.length > 2 && void 0 !== arguments[2]) ||
                  arguments[2];
                if (t) {
                  const n = this.element.getBoundingClientRect(),
                    o = this.isVertical ? "height" : "width",
                    i = this.isVertical ? "y" : "x",
                    a = this.isVertical ? "top" : "left";
                  e[i] < 0 && (e[i] = 0),
                    e[i] > n[o] && (e[i] = n[o]),
                    t === this.handler1 &&
                      e[i] >= this.handler2[a] &&
                      (e[i] = this.handler2[a]),
                    t === this.handler2 &&
                      e[i] <= this.handler1[a] &&
                      (e[i] = this.handler1[a]),
                    (t[a] = e[i]),
                    (t.value = this.getPreviewValue(e[i], this.min, this.max)),
                    (t.style[a] = e[i] + "px"),
                    (this.range.style[a] = this.handler1[a] + "px");
                  const l = this.handler2[a] - this.handler1[a];
                  (this.range.style[o] = (l >= 0 ? l : 0) + "px"),
                    this.callback &&
                      r &&
                      this.callback(this.handler1.value, this.handler2.value);
                }
              },
            },
            {
              key: "getHandlerPos",
              value: function (t) {
                const r = this.element.getBoundingClientRect(),
                  n = {
                    x:
                      t.touches && t.touches.length > 0
                        ? t.touches[0].pageX
                        : t.clientX,
                    y:
                      t.touches && t.touches.length > 0
                        ? t.touches[0].pageY
                        : t.clientY,
                  },
                  o = { x: r.left, y: r.top };
                return e.sub(n, o);
              },
            },
          ],
          [
            {
              key: "sub",
              value: function (e, t) {
                return { x: e.x - t.x, y: e.y - t.y };
              },
            },
          ]
        ),
        e
      );
    })();
    t.default = o;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = a(r(10)),
      i = a(r(19));
    function a(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const l = (function (e) {
      function t(e, r) {
        const n =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : [],
          o =
            arguments.length > 3 && void 0 !== arguments[3]
              ? arguments[3]
              : null;
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, t);
        const i = (function (e, t) {
          if (!e)
            throw new ReferenceError(
              "this hasn't been initialised - super() hasn't been called"
            );
          return !t || ("object" != typeof t && "function" != typeof t) ? e : t;
        })(
          this,
          (t.__proto__ || Object.getPrototypeOf(t)).call(this, e, r, n, o)
        );
        return (i.group = e), (i.name = r), i;
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, o.default),
        n(t, [
          {
            key: "addControl",
            value: function (e) {
              const r = this,
                n = (function e(t, r, n) {
                  null === t && (t = Function.prototype);
                  const o = Object.getOwnPropertyDescriptor(t, r);
                  if (void 0 === o) {
                    const i = Object.getPrototypeOf(t);
                    return null === i ? void 0 : e(i, r, n);
                  }
                  if ("value" in o) return o.value;
                  const a = o.get;
                  return void 0 !== a ? a.call(n) : void 0;
                })(
                  t.prototype.__proto__ || Object.getPrototypeOf(t.prototype),
                  "addControl",
                  this
                ).call(this, e),
                o = e.element.querySelector('[data-type="slider"]');
              if (
                ((n.val1Elements = e.element.querySelectorAll(
                  '[data-type="value-1"]'
                )),
                (n.val2Elements = e.element.querySelectorAll(
                  '[data-type="value-2"]'
                )),
                (n.minElements =
                  e.element.querySelectorAll('[data-type="min"]')),
                (n.maxElements =
                  e.element.querySelectorAll('[data-type="max"]')),
                o)
              ) {
                let a =
                    e.element.getAttribute("data-orientation") || "horizontal",
                  l = !0,
                  u = !1,
                  s = void 0;
                try {
                  for (
                    var c, f = n.minElements[Symbol.iterator]();
                    !(l = (c = f.next()).done);
                    l = !0
                  ) {
                    c.value.textContent = n.min;
                  }
                } catch (e) {
                  (u = !0), (s = e);
                } finally {
                  try {
                    !l && f.return && f.return();
                  } finally {
                    if (u) throw s;
                  }
                }
                let d = !0,
                  p = !1,
                  h = void 0;
                try {
                  for (
                    var v, y = n.maxElements[Symbol.iterator]();
                    !(d = (v = y.next()).done);
                    d = !0
                  ) {
                    v.value.textContent = n.max;
                  }
                } catch (e) {
                  (p = !0), (h = e);
                } finally {
                  try {
                    !d && y.return && y.return();
                  } finally {
                    if (p) throw h;
                  }
                }
                if (n.id) {
                  const b = this.deepLinkParams.find(function (e) {
                    return e.key === n.id;
                  });
                  if (b && b.value) {
                    const m = b.value.split("_");
                    2 === m.length &&
                      ((n.from = Number(m[0]) || 0),
                      (n.to = Number(m[1]) || 0));
                  }
                }
                n.slider = new i.default(
                  o,
                  "vertical" === a,
                  n.min,
                  n.from,
                  n.to,
                  n.max,
                  function (e, t) {
                    let o = !0,
                      i = !1,
                      a = void 0;
                    try {
                      for (
                        var l, u = n.val1Elements[Symbol.iterator]();
                        !(o = (l = u.next()).done);
                        o = !0
                      ) {
                        l.value.textContent = Math.round(e);
                      }
                    } catch (e) {
                      (i = !0), (a = e);
                    } finally {
                      try {
                        !o && u.return && u.return();
                      } finally {
                        if (i) throw a;
                      }
                    }
                    let s = !0,
                      c = !1,
                      f = void 0;
                    try {
                      for (
                        var d, p = n.val2Elements[Symbol.iterator]();
                        !(s = (d = p.next()).done);
                        s = !0
                      ) {
                        d.value.textContent = Math.round(t);
                      }
                    } catch (e) {
                      (c = !0), (f = e);
                    } finally {
                      try {
                        !s && p.return && p.return();
                      } finally {
                        if (c) throw f;
                      }
                    }
                    let h = !0,
                      v = !1,
                      y = void 0;
                    try {
                      for (
                        var b, m = r.controls[Symbol.iterator]();
                        !(h = (b = m.next()).done);
                        h = !0
                      ) {
                        const g = b.value;
                        g.slider && g.slider.setValues(e, t, !1);
                      }
                    } catch (e) {
                      (v = !0), (y = e);
                    } finally {
                      try {
                        !h && m.return && m.return();
                      } finally {
                        if (v) throw y;
                      }
                    }
                    window.jplist && window.jplist.refresh(r.group, n);
                  }
                );
              }
            },
          },
          {
            key: "getRangeFilterOptions",
            value: function () {
              let e = [],
                t = !0,
                r = !1,
                n = void 0;
              try {
                for (
                  var o, i = this.controls[Symbol.iterator]();
                  !(t = (o = i.next()).done);
                  t = !0
                ) {
                  const a = o.value;
                  if (a.slider && a.slider.handler1 && a.slider.handler2) {
                    const l = a.getRangeFilterOptions();
                    (l.from = a.slider.handler1.value),
                      (l.to = a.slider.handler2.value),
                      (e = e.concat(l));
                  }
                }
              } catch (e) {
                (r = !0), (n = e);
              } finally {
                try {
                  !t && i.return && i.return();
                } finally {
                  if (r) throw n;
                }
              }
              return e;
            },
          },
          {
            key: "getDeepLink",
            value: function () {
              const e = this.controls
                .map(function (e) {
                  return e.id &&
                    e.slider &&
                    e.slider.handler1 &&
                    e.slider.handler2
                    ? e.id +
                        "=" +
                        e.slider.handler1.value +
                        "_" +
                        e.slider.handler2.value
                    : "";
                })
                .filter(function (e) {
                  return "" !== e;
                });
              return Array.from(new Set(e)).join("&");
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = a(r(10)),
      i = a(r(7));
    function a(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const l = (function (e) {
      function t() {
        return (
          (function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
          (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (t.__proto__ || Object.getPrototypeOf(t)).apply(this, arguments)
          )
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, (0, i.default)(o.default)),
        n(t, [
          {
            key: "getRangeFilterOptions",
            value: function () {
              let e = [],
                t = this.getLastSelectedRadio();
              t && (e = e.concat(t.getRangeFilterOptions()));
              let r = !0,
                n = !1,
                o = void 0;
              try {
                for (
                  var i, a = this.checkboxes[Symbol.iterator]();
                  !(r = (i = a.next()).done);
                  r = !0
                ) {
                  const l = i.value;
                  l.selected && (e = e.concat(l.getRangeFilterOptions()));
                }
              } catch (e) {
                (n = !0), (o = e);
              } finally {
                try {
                  !r && a.return && a.return();
                } finally {
                  if (n) throw o;
                }
              }
              return e;
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = l(r(3)),
      i = l(r(4)),
      a = l(r(6));
    function l(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const u = (function (e) {
      function t(e, r) {
        const n =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : [],
          o =
            arguments.length > 3 && void 0 !== arguments[3]
              ? arguments[3]
              : null;
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, t);
        const i = (function (e, t) {
          if (!e)
            throw new ReferenceError(
              "this hasn't been initialised - super() hasn't been called"
            );
          return !t || ("object" != typeof t && "function" != typeof t) ? e : t;
        })(
          this,
          (t.__proto__ || Object.getPrototypeOf(t)).call(this, e, r, n, o)
        );
        return (i.group = e), (i.name = r), (i.selected = ""), (i.id = ""), i;
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, o.default),
        n(
          t,
          [
            {
              key: "addControl",
              value: function (e) {
                const r = this,
                  n = (function e(t, r, n) {
                    null === t && (t = Function.prototype);
                    const o = Object.getOwnPropertyDescriptor(t, r);
                    if (void 0 === o) {
                      const i = Object.getPrototypeOf(t);
                      return null === i ? void 0 : e(i, r, n);
                    }
                    if ("value" in o) return o.value;
                    const a = o.get;
                    return void 0 !== a ? a.call(n) : void 0;
                  })(
                    t.prototype.__proto__ || Object.getPrototypeOf(t.prototype),
                    "addControl",
                    this
                  ).call(this, e);
                (n.dropdown = new a.default(e.element)), (n.buttons = []);
                let o = n.element.querySelectorAll("[data-path]"),
                  l = !0,
                  u = !1,
                  s = void 0;
                try {
                  for (
                    var c,
                      f = function () {
                        const e = c.value;
                        e.setAttribute("data-name", r.name),
                          e.setAttribute("data-group", r.group),
                          e.setAttribute("data-jump", n.jump);
                        const t = new i.default(e);
                        n.buttons.push(t),
                          t.element.addEventListener("click", function (e) {
                            e.preventDefault(),
                              (r.selected = t),
                              r.setSelectedButton(n),
                              window.jplist &&
                                window.jplist.refresh(r.group, t);
                          });
                      },
                      d = o[Symbol.iterator]();
                    !(l = (c = d.next()).done);
                    l = !0
                  )
                    f();
                } catch (e) {
                  (u = !0), (s = e);
                } finally {
                  try {
                    !l && d.return && d.return();
                  } finally {
                    if (u) throw s;
                  }
                }
                if (
                  ((this.selected = t.getSelectedButton(n.buttons)),
                  this.setSelectedButton(n),
                  n.id)
                ) {
                  this.id = n.id;
                  const p = this.deepLinkParams.find(function (e) {
                    return e.key === n.id;
                  });
                  if (p) {
                    const h = n.buttons.find(function (e) {
                      const t = e.element.getAttribute("data-value");
                      return p.value === t ? e : null;
                    });
                    h && ((this.selected = h), this.setSelectedButton(n));
                  }
                }
              },
            },
            {
              key: "getPathFilterOptions",
              value: function () {
                return this.selected
                  ? [this.selected.getPathFilterOptions()]
                  : [];
              },
            },
            {
              key: "getDeepLink",
              value: function () {
                return (
                  (this.id &&
                    this.selected &&
                    this.id +
                      "=" +
                      this.selected.element.getAttribute("data-value")) ||
                  ""
                );
              },
            },
            {
              key: "setSelectedButton",
              value: function (e) {
                let t = this,
                  r = !0,
                  n = !1,
                  o = void 0;
                try {
                  for (
                    var i, a = this.controls[Symbol.iterator]();
                    !(r = (i = a.next()).done);
                    r = !0
                  ) {
                    const l = i.value;
                    if (l.dropdown) {
                      const u = e.buttons.find(function (e) {
                        return t.selected.isEqualTo(e);
                      });
                      u && l.dropdown.setPanelsContent(u.element.textContent),
                        l.dropdown.close();
                    }
                  }
                } catch (e) {
                  (n = !0), (o = e);
                } finally {
                  try {
                    !r && a.return && a.return();
                  } finally {
                    if (n) throw o;
                  }
                }
              },
            },
          ],
          [
            {
              key: "getSelectedButton",
              value: function (e) {
                if (e.length <= 0) return null;
                let t = !0,
                  r = !1,
                  n = void 0;
                try {
                  for (
                    var o, i = e[Symbol.iterator]();
                    !(t = (o = i.next()).done);
                    t = !0
                  ) {
                    const a = o.value;
                    if ("true" === a.element.getAttribute("data-selected"))
                      return a;
                  }
                } catch (e) {
                  (r = !0), (n = e);
                } finally {
                  try {
                    !t && i.return && i.return();
                  } finally {
                    if (r) throw n;
                  }
                }
                return e[0];
              },
            },
          ]
        ),
        t
      );
    })();
    t.default = u;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = a(r(3)),
      i = a(r(7));
    function a(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const l = (function (e) {
      function t() {
        return (
          (function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
          (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (t.__proto__ || Object.getPrototypeOf(t)).apply(this, arguments)
          )
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, (0, i.default)(o.default)),
        n(t, [
          {
            key: "getPathFilterOptions",
            value: function () {
              let e = [],
                t = this.getLastSelectedRadio();
              t && (e = e.concat(t.getPathFilterOptions()));
              let r = !0,
                n = !1,
                o = void 0;
              try {
                for (
                  var i, a = this.checkboxes[Symbol.iterator]();
                  !(r = (i = a.next()).done);
                  r = !0
                ) {
                  const l = i.value;
                  l.selected && (e = e.concat(l.getPathFilterOptions()));
                }
              } catch (e) {
                (n = !0), (o = e);
              } finally {
                try {
                  !r && a.return && a.return();
                } finally {
                  if (n) throw o;
                }
              }
              return e;
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = a(r(3)),
      i = a(r(11));
    function a(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const l = (function (e) {
      function t() {
        return (
          (function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
          (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (t.__proto__ || Object.getPrototypeOf(t)).apply(this, arguments)
          )
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, (0, i.default)(o.default)),
        n(t, [
          {
            key: "getPathFilterOptions",
            value: function () {
              let e = [],
                t = this.getLastSelectedRadio();
              return t && (e = e.concat(t.getPathFilterOptions())), e;
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = a(r(3)),
      i = a(r(12));
    function a(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const l = (function (e) {
      function t() {
        return (
          (function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
          (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (t.__proto__ || Object.getPrototypeOf(t)).apply(this, arguments)
          )
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, (0, i.default)(o.default)),
        n(t, [
          {
            key: "getPathFilterOptions",
            value: function () {
              let e = [],
                t = !0,
                r = !1,
                n = void 0;
              try {
                for (
                  var o, i = this.checkboxes[Symbol.iterator]();
                  !(t = (o = i.next()).done);
                  t = !0
                ) {
                  const a = o.value;
                  a.selected && (e = e.concat(a.getPathFilterOptions()));
                }
              } catch (e) {
                (r = !0), (n = e);
              } finally {
                try {
                  !t && i.return && i.return();
                } finally {
                  if (r) throw n;
                }
              }
              return e;
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = a(r(3)),
      i = a(r(4));
    function a(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const l = (function (e) {
      function t(e, r) {
        const n =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : [],
          o =
            arguments.length > 3 && void 0 !== arguments[3]
              ? arguments[3]
              : null;
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, t);
        const i = (function (e, t) {
          if (!e)
            throw new ReferenceError(
              "this hasn't been initialised - super() hasn't been called"
            );
          return !t || ("object" != typeof t && "function" != typeof t) ? e : t;
        })(
          this,
          (t.__proto__ || Object.getPrototypeOf(t)).call(this, e, r, n, o)
        );
        return (
          (i.group = e),
          (i.name = r),
          (i.options = []),
          (i.selected = ""),
          (i.id = ""),
          i
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, o.default),
        n(t, [
          {
            key: "addControl",
            value: function (e) {
              let r = this,
                n = (function e(t, r, n) {
                  null === t && (t = Function.prototype);
                  const o = Object.getOwnPropertyDescriptor(t, r);
                  if (void 0 === o) {
                    const i = Object.getPrototypeOf(t);
                    return null === i ? void 0 : e(i, r, n);
                  }
                  if ("value" in o) return o.value;
                  const a = o.get;
                  return void 0 !== a ? a.call(n) : void 0;
                })(
                  t.prototype.__proto__ || Object.getPrototypeOf(t.prototype),
                  "addControl",
                  this
                ).call(this, e),
                o = n.element.querySelectorAll("option"),
                a = !0,
                l = !1,
                u = void 0;
              try {
                for (
                  var s,
                    c = function () {
                      const e = s.value;
                      e.setAttribute("data-name", r.name),
                        e.setAttribute("data-group", r.group),
                        r.options.find(function (t) {
                          return t.element.value === e.value;
                        }) || r.options.push(new i.default(e));
                    },
                    f = o[Symbol.iterator]();
                  !(a = (s = f.next()).done);
                  a = !0
                )
                  c();
              } catch (e) {
                (l = !0), (u = e);
              } finally {
                try {
                  !a && f.return && f.return();
                } finally {
                  if (l) throw u;
                }
              }
              if (((this.selected = n.element.value), n.id)) {
                this.id = n.id;
                const d = this.deepLinkParams.find(function (e) {
                  return e.key === n.id;
                });
                d && ((n.element.value = d.value), (this.selected = d.value));
              }
              n.element.addEventListener("change", function (e) {
                e.preventDefault(), (r.selected = e.target.value);
                let t = !0,
                  o = !1,
                  i = void 0;
                try {
                  for (
                    var a, l = r.controls[Symbol.iterator]();
                    !(t = (a = l.next()).done);
                    t = !0
                  ) {
                    a.value.element.value = r.selected;
                  }
                } catch (e) {
                  (o = !0), (i = e);
                } finally {
                  try {
                    !t && l.return && l.return();
                  } finally {
                    if (o) throw i;
                  }
                }
                window.jplist && window.jplist.refresh(r.group, n);
              });
            },
          },
          {
            key: "getPathFilterOptions",
            value: function () {
              const e = this,
                t = this.options.find(function (t) {
                  return t.element.value === e.selected;
                });
              return t ? [t.getPathFilterOptions()] : [];
            },
          },
          {
            key: "getDeepLink",
            value: function () {
              const e = this,
                t = this.options.find(function (t) {
                  return t.element.value === e.selected;
                });
              return this.id ? this.id + "=" + t.element.value : "";
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = a(r(5)),
      i = a(r(7));
    function a(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const l = (function (e) {
      function t() {
        return (
          (function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
          (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (t.__proto__ || Object.getPrototypeOf(t)).apply(this, arguments)
          )
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, (0, i.default)(o.default)),
        n(t, [
          {
            key: "getTextFilterOptions",
            value: function () {
              let e = [],
                t = this.getLastSelectedRadio();
              t && (e = e.concat(t.getTextFilterOptions()));
              let r = !0,
                n = !1,
                o = void 0;
              try {
                for (
                  var i, a = this.checkboxes[Symbol.iterator]();
                  !(r = (i = a.next()).done);
                  r = !0
                ) {
                  const l = i.value;
                  l.selected && (e = e.concat(l.getTextFilterOptions()));
                }
              } catch (e) {
                (n = !0), (o = e);
              } finally {
                try {
                  !r && a.return && a.return();
                } finally {
                  if (n) throw o;
                }
              }
              return e;
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = a(r(5)),
      i = a(r(11));
    function a(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const l = (function (e) {
      function t() {
        return (
          (function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
          (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (t.__proto__ || Object.getPrototypeOf(t)).apply(this, arguments)
          )
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, (0, i.default)(o.default)),
        n(t, [
          {
            key: "getTextFilterOptions",
            value: function () {
              let e = [],
                t = this.getLastSelectedRadio();
              return t && (e = e.concat(t.getTextFilterOptions())), e;
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = a(r(5)),
      i = a(r(12));
    function a(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const l = (function (e) {
      function t() {
        return (
          (function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
          (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (t.__proto__ || Object.getPrototypeOf(t)).apply(this, arguments)
          )
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, (0, i.default)(o.default)),
        n(t, [
          {
            key: "getTextFilterOptions",
            value: function () {
              let e = [],
                t = !0,
                r = !1,
                n = void 0;
              try {
                for (
                  var o, i = this.checkboxes[Symbol.iterator]();
                  !(t = (o = i.next()).done);
                  t = !0
                ) {
                  const a = o.value;
                  a.selected && (e = e.concat(a.getTextFilterOptions()));
                }
              } catch (e) {
                (r = !0), (n = e);
              } finally {
                try {
                  !t && i.return && i.return();
                } finally {
                  if (r) throw n;
                }
              }
              return e;
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    let n,
      o = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      i = r(5),
      a = (n = i) && n.__esModule ? n : { default: n };
    const l = (function (e) {
      function t(e, r) {
        const n =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : [],
          o =
            arguments.length > 3 && void 0 !== arguments[3]
              ? arguments[3]
              : null;
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, t);
        const i = (function (e, t) {
          if (!e)
            throw new ReferenceError(
              "this hasn't been initialised - super() hasn't been called"
            );
          return !t || ("object" != typeof t && "function" != typeof t) ? e : t;
        })(
          this,
          (t.__proto__ || Object.getPrototypeOf(t)).call(this, e, r, n, o)
        );
        return (i.group = e), (i.name = r), i;
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, a.default),
        o(t, [
          {
            key: "addControl",
            value: function (e) {
              const r = this,
                n = (function e(t, r, n) {
                  null === t && (t = Function.prototype);
                  const o = Object.getOwnPropertyDescriptor(t, r);
                  if (void 0 === o) {
                    const i = Object.getPrototypeOf(t);
                    return null === i ? void 0 : e(i, r, n);
                  }
                  if ("value" in o) return o.value;
                  const a = o.get;
                  return void 0 !== a ? a.call(n) : void 0;
                })(
                  t.prototype.__proto__ || Object.getPrototypeOf(t.prototype),
                  "addControl",
                  this
                ).call(this, e);
              if (n.id) {
                const o = this.deepLinkParams.find(function (e) {
                  return e.key === n.id;
                });
                o && ((n.text = o.value), (n.element.value = o.value));
              }
              if (
                (n.element.addEventListener("keyup", function (e) {
                  e.preventDefault(),
                    (n.text = e.target.value),
                    r.textChanged(n);
                }),
                (n.clearButtonID = (
                  n.element.getAttribute("data-clear-btn-id") || ""
                ).trim()),
                n.clearButtonID)
              ) {
                const i = document.getElementById(n.clearButtonID);
                i &&
                  i.addEventListener("click", function (e) {
                    e.preventDefault(), (n.text = ""), r.textChanged(n);
                  });
              }
            },
          },
          {
            key: "textChanged",
            value: function (e) {
              this.controls.forEach(function (t) {
                t.isEqualTo(e, !1) &&
                  ((t.element.value = e.initialText), (t.text = e.initialText));
              }),
                window.jplist && window.jplist.refresh(this.group, e);
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    let n,
      o = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      i = r(1),
      a = (n = i) && n.__esModule ? n : { default: n };
    const l = (function (e) {
      function t(e) {
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, t);
        const r = (function (e, t) {
          if (!e)
            throw new ReferenceError(
              "this hasn't been initialised - super() hasn't been called"
            );
          return !t || ("object" != typeof t && "function" != typeof t) ? e : t;
        })(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this, e));
        return (
          e &&
            ((r.itemsPerPage =
              Number(e.getAttribute("data-items-per-page")) || 10),
            (r.currentPage = Number(e.getAttribute("data-current-page")) || 0),
            (r.range = Number(e.getAttribute("data-range")) || 10),
            (r.disabledClass = (
              e.getAttribute("data-disabled-class") || "jplist-disabled"
            ).trim()),
            (r.selectedClass = (
              e.getAttribute("data-selected-class") || "jplist-selected"
            ).trim())),
          r
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, a.default),
        o(t, [
          {
            key: "getPaginationOptions",
            value: function () {
              return {
                itemsPerPage: this.itemsPerPage,
                currentPage: this.currentPage,
                range: this.range,
              };
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = a(r(0)),
      i = a(r(31));
    function a(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const l = (function (e) {
      function t() {
        return (
          (function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
          (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (t.__proto__ || Object.getPrototypeOf(t)).apply(this, arguments)
          )
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, o.default),
        n(t, [
          {
            key: "getPaginationOptions",
            value: function () {
              return this.controls.length > 0
                ? this.controls[this.controls.length - 1].getPaginationOptions()
                : null;
            },
          },
          { key: "setPaginationOptions", value: function (e) {} },
          {
            key: "addControl",
            value: function (e) {
              if (e.name !== this.name || e.group !== this.group) return null;
              const t = new i.default(e.element);
              return this.controls.push(t), t;
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = a(r(32)),
      i = a(r(6));
    function a(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const l = (function (e) {
      function t(e, r) {
        const n =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : [],
          o =
            arguments.length > 3 && void 0 !== arguments[3]
              ? arguments[3]
              : null;
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, t);
        const i = (function (e, t) {
          if (!e)
            throw new ReferenceError(
              "this hasn't been initialised - super() hasn't been called"
            );
          return !t || ("object" != typeof t && "function" != typeof t) ? e : t;
        })(
          this,
          (t.__proto__ || Object.getPrototypeOf(t)).call(this, e, r, n, o)
        );
        return (
          (i.currentPage = 0),
          (i.itemsPerPage = 0),
          (i.range = 0),
          (i.id = ""),
          i
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, o.default),
        n(
          t,
          [
            {
              key: "addControl",
              value: function (e) {
                const r = (function e(t, r, n) {
                  null === t && (t = Function.prototype);
                  const o = Object.getOwnPropertyDescriptor(t, r);
                  if (void 0 === o) {
                    const i = Object.getPrototypeOf(t);
                    return null === i ? void 0 : e(i, r, n);
                  }
                  if ("value" in o) return o.value;
                  const a = o.get;
                  return void 0 !== a ? a.call(n) : void 0;
                })(
                  t.prototype.__proto__ || Object.getPrototypeOf(t.prototype),
                  "addControl",
                  this
                ).call(this, e);
                if (
                  ((this.currentPage = r.currentPage),
                  (this.itemsPerPage = Number(r.itemsPerPage) || 0),
                  (this.range = r.range),
                  this.restoreFromDeepLink(r),
                  (r.pageButtonsHolder = r.element.querySelector(
                    '[data-type="pages"]'
                  )),
                  r.pageButtonsHolder &&
                    (r.btnTemplate = r.pageButtonsHolder.innerHTML),
                  (r.firstButtons = r.element.querySelectorAll(
                    '[data-type="first"]'
                  )),
                  (r.lastButtons =
                    r.element.querySelectorAll('[data-type="last"]')),
                  (r.prevButtons =
                    r.element.querySelectorAll('[data-type="prev"]')),
                  (r.nextButtons =
                    r.element.querySelectorAll('[data-type="next"]')),
                  t.bindEventHandler(
                    r.firstButtons,
                    "click",
                    this.pageButtonClick.bind(this),
                    r
                  ),
                  t.bindEventHandler(
                    r.lastButtons,
                    "click",
                    this.pageButtonClick.bind(this),
                    r
                  ),
                  t.bindEventHandler(
                    r.prevButtons,
                    "click",
                    this.pageButtonClick.bind(this),
                    r
                  ),
                  t.bindEventHandler(
                    r.nextButtons,
                    "click",
                    this.pageButtonClick.bind(this),
                    r
                  ),
                  (r.itemsPerPageSelects = Array.from(
                    r.element.querySelectorAll('[data-type="items-per-page"]')
                  )),
                  this.updateItemsPerPageSelect(r.itemsPerPageSelects),
                  (r.itemsPerPageDD = Array.from(
                    r.element.querySelectorAll(
                      '[data-type="items-per-page-dd"]'
                    )
                  )),
                  this.initCustomDropdowns(r),
                  t.bindEventHandler(
                    r.itemsPerPageSelects,
                    "change",
                    this.selectChange.bind(this),
                    r
                  ),
                  (r.labels = r.element.querySelectorAll('[data-type="info"]')),
                  r.labels)
                ) {
                  let n = !0,
                    o = !1,
                    i = void 0;
                  try {
                    for (
                      var a, l = r.labels[Symbol.iterator]();
                      !(n = (a = l.next()).done);
                      n = !0
                    ) {
                      const u = a.value;
                      u.template = u.innerHTML;
                    }
                  } catch (e) {
                    (o = !0), (i = e);
                  } finally {
                    try {
                      !n && l.return && l.return();
                    } finally {
                      if (o) throw i;
                    }
                  }
                }
              },
            },
            {
              key: "updateItemsPerPageSelect",
              value: function (e) {
                let t = this,
                  r = !0,
                  n = !1,
                  o = void 0;
                try {
                  for (
                    var i, a = e[Symbol.iterator]();
                    !(r = (i = a.next()).done);
                    r = !0
                  ) {
                    const l = i.value,
                      u = Array.from(l.options).find(function (e) {
                        return e.value === t.itemsPerPage.toString();
                      });
                    l.value = (u && Number(this.itemsPerPage)) || 0;
                  }
                } catch (e) {
                  (n = !0), (o = e);
                } finally {
                  try {
                    !r && a.return && a.return();
                  } finally {
                    if (n) throw o;
                  }
                }
              },
            },
            {
              key: "initCustomDropdowns",
              value: function (e) {
                const t = this,
                  r = e.itemsPerPageDD;
                if (r && !(r.length <= 0)) {
                  let n = !0,
                    o = !1,
                    a = void 0;
                  try {
                    for (
                      var l,
                        u = function () {
                          const r = l.value;
                          (r.dropdown = new i.default(r)),
                            (r.buttons = Array.from(
                              r.querySelectorAll("[data-value]")
                            ));
                          let n = !0,
                            o = !1,
                            a = void 0;
                          try {
                            for (
                              var u,
                                s = function () {
                                  const n = u.value;
                                  n.addEventListener("click", function (o) {
                                    o.preventDefault(),
                                      (t.itemsPerPage =
                                        Number(n.getAttribute("data-value")) ||
                                        0),
                                      t.setSelectedButton(),
                                      r.dropdown.close(),
                                      window.jplist &&
                                        window.jplist.refresh(t.group, e);
                                  });
                                },
                                c = r.buttons[Symbol.iterator]();
                              !(n = (u = c.next()).done);
                              n = !0
                            )
                              s();
                          } catch (e) {
                            (o = !0), (a = e);
                          } finally {
                            try {
                              !n && c.return && c.return();
                            } finally {
                              if (o) throw a;
                            }
                          }
                        },
                        s = r[Symbol.iterator]();
                      !(n = (l = s.next()).done);
                      n = !0
                    )
                      u();
                  } catch (e) {
                    (o = !0), (a = e);
                  } finally {
                    try {
                      !n && s.return && s.return();
                    } finally {
                      if (o) throw a;
                    }
                  }
                  this.setSelectedButton();
                }
              },
            },
            {
              key: "setSelectedButton",
              value: function () {
                let e = this,
                  t = !0,
                  r = !1,
                  n = void 0;
                try {
                  for (
                    var o, i = this.controls[Symbol.iterator]();
                    !(t = (o = i.next()).done);
                    t = !0
                  ) {
                    const a = o.value;
                    if (a.itemsPerPageDD) {
                      let l = !0,
                        u = !1,
                        s = void 0;
                      try {
                        for (
                          var c, f = a.itemsPerPageDD[Symbol.iterator]();
                          !(l = (c = f.next()).done);
                          l = !0
                        ) {
                          const d = c.value;
                          if (d.buttons) {
                            let p = d.buttons.find(function (t) {
                              return (
                                (Number(t.getAttribute("data-value")) || 0) ===
                                e.itemsPerPage
                              );
                            });
                            p ||
                              (p = d.buttons.find(function (e) {
                                return (
                                  0 ===
                                  (Number(e.getAttribute("data-value")) || 0)
                                );
                              })),
                              p && d.dropdown.setPanelsContent(p.textContent);
                          }
                        }
                      } catch (e) {
                        (u = !0), (s = e);
                      } finally {
                        try {
                          !l && f.return && f.return();
                        } finally {
                          if (u) throw s;
                        }
                      }
                    }
                  }
                } catch (e) {
                  (r = !0), (n = e);
                } finally {
                  try {
                    !t && i.return && i.return();
                  } finally {
                    if (r) throw n;
                  }
                }
              },
            },
            {
              key: "getPaginationOptions",
              value: function () {
                return {
                  itemsPerPage: this.itemsPerPage,
                  currentPage: this.currentPage,
                  range: this.range,
                };
              },
            },
            {
              key: "setPaginationOptions",
              value: function (e) {
                const r = this;
                if (e) {
                  (this.currentPage = e.currentPage),
                    (this.itemsPerPage = e.itemsPerPage);
                  let n = !0,
                    o = !1,
                    i = void 0;
                  try {
                    for (
                      var a,
                        l = function () {
                          const n = a.value;
                          if (!n.btnTemplate || !n.pageButtonsHolder)
                            return "continue";
                          for (; n.pageButtonsHolder.firstChild; )
                            n.pageButtonsHolder.removeChild(
                              n.pageButtonsHolder.firstChild
                            );
                          for (
                            let o = function (e) {
                                const t = document.createElement("div");
                                t.innerHTML = n.btnTemplate
                                  .replace(
                                    new RegExp("{pageNumber}", "g"),
                                    e + 1
                                  )
                                  .trim();
                                let o = t.firstChild,
                                  i = o.querySelector('[data-type="page"]');
                                i || (i = o),
                                  i.setAttribute("data-page", e.toString()),
                                  e === r.currentPage &&
                                    (i.classList.add(n.selectedClass),
                                    i.setAttribute("data-selected", "true")),
                                  i.addEventListener("click", function (e) {
                                    r.pageButtonClick(e, i, n);
                                  }),
                                  n.pageButtonsHolder.appendChild(o);
                              },
                              i = e.rangeStart;
                            i <= e.rangeEnd;
                            i++
                          )
                            o(i);
                          t.setPageAttr(
                            n.firstButtons,
                            0,
                            0 !== r.currentPage,
                            n.disabledClass
                          ),
                            t.setPageAttr(
                              n.lastButtons,
                              e.pagesNumber - 1,
                              r.currentPage !== e.pagesNumber - 1,
                              n.disabledClass
                            ),
                            t.setPageAttr(
                              n.prevButtons,
                              e.prevPage,
                              0 !== r.currentPage,
                              n.disabledClass
                            ),
                            t.setPageAttr(
                              n.nextButtons,
                              e.nextPage,
                              r.currentPage !== e.pagesNumber - 1,
                              n.disabledClass
                            );
                          const l = [
                            { key: "{pageNumber}", value: e.currentPage + 1 },
                            { key: "{pagesNumber}", value: e.pagesNumber },
                            { key: "{startItem}", value: e.start + 1 },
                            { key: "{endItem}", value: e.end },
                            { key: "{itemsNumber}", value: e.itemsNumber },
                          ];
                          if (n.labels) {
                            let u = !0,
                              s = !1,
                              c = void 0;
                            try {
                              for (
                                var f, d = n.labels[Symbol.iterator]();
                                !(u = (f = d.next()).done);
                                u = !0
                              ) {
                                const p = f.value;
                                if (p.template) {
                                  let h = p.template,
                                    v = !0,
                                    y = !1,
                                    b = void 0;
                                  try {
                                    for (
                                      var m, g = l[Symbol.iterator]();
                                      !(v = (m = g.next()).done);
                                      v = !0
                                    ) {
                                      const w = m.value;
                                      h = h.replace(
                                        new RegExp(w.key, "g"),
                                        w.value
                                      );
                                    }
                                  } catch (e) {
                                    (y = !0), (b = e);
                                  } finally {
                                    try {
                                      !v && g.return && g.return();
                                    } finally {
                                      if (y) throw b;
                                    }
                                  }
                                  p.innerHTML = h;
                                }
                              }
                            } catch (e) {
                              (s = !0), (c = e);
                            } finally {
                              try {
                                !u && d.return && d.return();
                              } finally {
                                if (s) throw c;
                              }
                            }
                          }
                          let O = Array.from(n.element.classList).filter(
                              function (e) {
                                return (
                                  e.startsWith("jplist-pages-number-") ||
                                  e.startsWith("jplist-items-number-")
                                );
                              }
                            ),
                            _ = !0,
                            j = !1,
                            P = void 0;
                          try {
                            for (
                              var k, x = O[Symbol.iterator]();
                              !(_ = (k = x.next()).done);
                              _ = !0
                            ) {
                              const S = k.value;
                              n.element.classList.remove(S);
                            }
                          } catch (e) {
                            (j = !0), (P = e);
                          } finally {
                            try {
                              !_ && x.return && x.return();
                            } finally {
                              if (j) throw P;
                            }
                          }
                          n.element.classList.add(
                            "jplist-pages-number-" + e.pagesNumber
                          ),
                            n.element.classList.add(
                              "jplist-items-number-" + e.itemsNumber
                            );
                        },
                        u = this.controls[Symbol.iterator]();
                      !(n = (a = u.next()).done);
                      n = !0
                    )
                      l();
                  } catch (e) {
                    (o = !0), (i = e);
                  } finally {
                    try {
                      !n && u.return && u.return();
                    } finally {
                      if (o) throw i;
                    }
                  }
                }
              },
            },
            {
              key: "pageButtonClick",
              value: function (e, t, r) {
                e && e.preventDefault();
                const n = t
                  ? t.getAttribute("data-page")
                  : e.target.getAttribute("data-page");
                (this.currentPage = Number(n) || 0),
                  window.jplist && window.jplist.refresh(this.group, r);
              },
            },
            {
              key: "selectChange",
              value: function (e, t, r) {
                e.preventDefault();
                const n = Number(e.target.value);
                if (!isNaN(n)) {
                  this.itemsPerPage = n;
                  let o = !0,
                    i = !1,
                    a = void 0;
                  try {
                    for (
                      var l, u = this.controls[Symbol.iterator]();
                      !(o = (l = u.next()).done);
                      o = !0
                    ) {
                      const s = l.value;
                      this.updateItemsPerPageSelect(s.itemsPerPageSelects);
                    }
                  } catch (e) {
                    (i = !0), (a = e);
                  } finally {
                    try {
                      !o && u.return && u.return();
                    } finally {
                      if (i) throw a;
                    }
                  }
                }
                window.jplist && window.jplist.refresh(this.group, r);
              },
            },
            {
              key: "restoreFromDeepLink",
              value: function (e) {
                if (e.id) {
                  this.id = e.id;
                  const t = this.deepLinkParams.find(function (t) {
                    return t.key === e.id;
                  });
                  if (t) {
                    const r = t.value.split("-");
                    if (2 !== r.length) return;
                    const n = Number(r[0]),
                      o = Number(r[1]);
                    if (isNaN(n) || isNaN(o)) return;
                    (this.currentPage = n), (this.itemsPerPage = o);
                  }
                }
              },
            },
            {
              key: "getDeepLink",
              value: function () {
                return this.id
                  ? this.id + "=" + this.currentPage + "-" + this.itemsPerPage
                  : "";
              },
            },
          ],
          [
            {
              key: "setPageAttr",
              value: function (e, t, r, n) {
                if (e) {
                  let o = !0,
                    i = !1,
                    a = void 0;
                  try {
                    for (
                      var l, u = e[Symbol.iterator]();
                      !(o = (l = u.next()).done);
                      o = !0
                    ) {
                      const s = l.value;
                      s.setAttribute("data-page", t),
                        r ? s.classList.remove(n) : s.classList.add(n);
                    }
                  } catch (e) {
                    (i = !0), (a = e);
                  } finally {
                    try {
                      !o && u.return && u.return();
                    } finally {
                      if (i) throw a;
                    }
                  }
                }
              },
            },
            {
              key: "bindEventHandler",
              value: function (e, t, r, n) {
                if (e) {
                  let o = !0,
                    i = !1,
                    a = void 0;
                  try {
                    for (
                      var l,
                        u = function () {
                          const e = l.value;
                          e.addEventListener(t, function (t) {
                            r(t, e, n);
                          });
                        },
                        s = e[Symbol.iterator]();
                      !(o = (l = s.next()).done);
                      o = !0
                    )
                      u();
                  } catch (e) {
                    (i = !0), (a = e);
                  } finally {
                    try {
                      !o && s.return && s.return();
                    } finally {
                      if (i) throw a;
                    }
                  }
                }
              },
            },
          ]
        ),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = l(r(2)),
      i = l(r(8)),
      a = l(r(6));
    function l(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const u = (function (e) {
      function t(e, r) {
        const n =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : [],
          o =
            arguments.length > 3 && void 0 !== arguments[3]
              ? arguments[3]
              : null;
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, t);
        const i = (function (e, t) {
          if (!e)
            throw new ReferenceError(
              "this hasn't been initialised - super() hasn't been called"
            );
          return !t || ("object" != typeof t && "function" != typeof t) ? e : t;
        })(
          this,
          (t.__proto__ || Object.getPrototypeOf(t)).call(this, e, r, n, o)
        );
        return (i.group = e), (i.name = r), (i.selected = null), (i.id = ""), i;
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, o.default),
        n(
          t,
          [
            {
              key: "addControl",
              value: function (e) {
                const r = this,
                  n = (function e(t, r, n) {
                    null === t && (t = Function.prototype);
                    const o = Object.getOwnPropertyDescriptor(t, r);
                    if (void 0 === o) {
                      const i = Object.getPrototypeOf(t);
                      return null === i ? void 0 : e(i, r, n);
                    }
                    if ("value" in o) return o.value;
                    const a = o.get;
                    return void 0 !== a ? a.call(n) : void 0;
                  })(
                    t.prototype.__proto__ || Object.getPrototypeOf(t.prototype),
                    "addControl",
                    this
                  ).call(this, e);
                (n.dropdown = new a.default(e.element)), (n.buttons = []);
                let o = n.element.querySelectorAll("[data-path]"),
                  l = !0,
                  u = !1,
                  s = void 0;
                try {
                  for (
                    var c,
                      f = function () {
                        const e = c.value;
                        e.setAttribute("data-name", r.name),
                          e.setAttribute("data-group", r.group),
                          e.setAttribute("data-jump", n.jump);
                        const t = new i.default(e);
                        n.buttons.push(t),
                          t.element.addEventListener("click", function (e) {
                            e.preventDefault(),
                              (r.selected = t),
                              r.setSelectedButton(n);
                            let o = !0,
                              i = !1,
                              a = void 0;
                            try {
                              for (
                                var l, u = r.controls[Symbol.iterator]();
                                !(o = (l = u.next()).done);
                                o = !0
                              ) {
                                const s = l.value;
                                s.dropdown && s.dropdown.close();
                              }
                            } catch (e) {
                              (i = !0), (a = e);
                            } finally {
                              try {
                                !o && u.return && u.return();
                              } finally {
                                if (i) throw a;
                              }
                            }
                            window.jplist && window.jplist.refresh(r.group, t);
                          });
                      },
                      d = o[Symbol.iterator]();
                    !(l = (c = d.next()).done);
                    l = !0
                  )
                    f();
                } catch (e) {
                  (u = !0), (s = e);
                } finally {
                  try {
                    !l && d.return && d.return();
                  } finally {
                    if (u) throw s;
                  }
                }
                if (
                  ((this.selected = t.getSelectedButton(n.buttons)),
                  this.setSelectedButton(n),
                  n.id)
                ) {
                  this.id = n.id;
                  const p = this.deepLinkParams.find(function (e) {
                    return e.key === n.id;
                  });
                  if (p) {
                    const h = n.buttons.find(function (e) {
                      const t = e.element.getAttribute("data-value");
                      return p.value === t ? e : null;
                    });
                    h && ((this.selected = h), this.setSelectedButton(n));
                  }
                }
              },
            },
            {
              key: "getSortOptions",
              value: function () {
                return this.selected ? this.selected.getSortOptions() : [];
              },
            },
            {
              key: "getDeepLink",
              value: function () {
                return (
                  (this.id &&
                    this.selected &&
                    this.id +
                      "=" +
                      this.selected.element.getAttribute("data-value")) ||
                  ""
                );
              },
            },
            {
              key: "setSelectedButton",
              value: function (e) {
                let t = this,
                  r = !0,
                  n = !1,
                  o = void 0;
                try {
                  for (
                    var i, a = this.controls[Symbol.iterator]();
                    !(r = (i = a.next()).done);
                    r = !0
                  ) {
                    const l = i.value;
                    if (l.dropdown) {
                      const u = e.buttons.find(function (e) {
                        return t.selected.isEqualTo(e);
                      });
                      u && l.dropdown.setPanelsContent(u.element.textContent);
                    }
                  }
                } catch (e) {
                  (n = !0), (o = e);
                } finally {
                  try {
                    !r && a.return && a.return();
                  } finally {
                    if (n) throw o;
                  }
                }
              },
            },
          ],
          [
            {
              key: "getSelectedButton",
              value: function (e) {
                if (e.length <= 0) return null;
                let t = !0,
                  r = !1,
                  n = void 0;
                try {
                  for (
                    var o, i = e[Symbol.iterator]();
                    !(t = (o = i.next()).done);
                    t = !0
                  ) {
                    const a = o.value;
                    if ("true" === a.element.getAttribute("data-selected"))
                      return a;
                  }
                } catch (e) {
                  (r = !0), (n = e);
                } finally {
                  try {
                    !t && i.return && i.return();
                  } finally {
                    if (r) throw n;
                  }
                }
                return e[0];
              },
            },
          ]
        ),
        t
      );
    })();
    t.default = u;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      o = a(r(2)),
      i = a(r(8));
    function a(e) {
      return e && e.__esModule ? e : { default: e };
    }
    const l = (function (e) {
      function t(e, r) {
        const n =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : [],
          o =
            arguments.length > 3 && void 0 !== arguments[3]
              ? arguments[3]
              : null;
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, t);
        const i = (function (e, t) {
          if (!e)
            throw new ReferenceError(
              "this hasn't been initialised - super() hasn't been called"
            );
          return !t || ("object" != typeof t && "function" != typeof t) ? e : t;
        })(
          this,
          (t.__proto__ || Object.getPrototypeOf(t)).call(this, e, r, n, o)
        );
        return (
          (i.group = e),
          (i.name = r),
          (i.options = []),
          (i.selected = ""),
          (i.id = ""),
          i
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, o.default),
        n(t, [
          {
            key: "addControl",
            value: function (e) {
              let r = this,
                n = (function e(t, r, n) {
                  null === t && (t = Function.prototype);
                  const o = Object.getOwnPropertyDescriptor(t, r);
                  if (void 0 === o) {
                    const i = Object.getPrototypeOf(t);
                    return null === i ? void 0 : e(i, r, n);
                  }
                  if ("value" in o) return o.value;
                  const a = o.get;
                  return void 0 !== a ? a.call(n) : void 0;
                })(
                  t.prototype.__proto__ || Object.getPrototypeOf(t.prototype),
                  "addControl",
                  this
                ).call(this, e),
                o = n.element.querySelectorAll("option"),
                a = !0,
                l = !1,
                u = void 0;
              try {
                for (
                  var s,
                    c = function () {
                      const e = s.value;
                      e.setAttribute("data-name", r.name),
                        e.setAttribute("data-group", r.group),
                        r.options.find(function (t) {
                          return t.element.value === e.value;
                        }) || r.options.push(new i.default(e));
                    },
                    f = o[Symbol.iterator]();
                  !(a = (s = f.next()).done);
                  a = !0
                )
                  c();
              } catch (e) {
                (l = !0), (u = e);
              } finally {
                try {
                  !a && f.return && f.return();
                } finally {
                  if (l) throw u;
                }
              }
              if (((this.selected = n.element.value), n.id)) {
                this.id = n.id;
                const d = this.deepLinkParams.find(function (e) {
                  return e.key === n.id;
                });
                d && ((n.element.value = d.value), (this.selected = d.value));
              }
              n.element.addEventListener("change", function (e) {
                e.preventDefault(), (r.selected = e.target.value);
                let t = !0,
                  o = !1,
                  i = void 0;
                try {
                  for (
                    var a, l = r.controls[Symbol.iterator]();
                    !(t = (a = l.next()).done);
                    t = !0
                  ) {
                    a.value.element.value = r.selected;
                  }
                } catch (e) {
                  (o = !0), (i = e);
                } finally {
                  try {
                    !t && l.return && l.return();
                  } finally {
                    if (o) throw i;
                  }
                }
                window.jplist && window.jplist.refresh(r.group, n);
              });
            },
          },
          {
            key: "getSortOptions",
            value: function () {
              const e = this,
                t = this.options.find(function (t) {
                  return t.element.value === e.selected;
                });
              return t ? t.getSortOptions() : [];
            },
          },
          {
            key: "getDeepLink",
            value: function () {
              const e = this,
                t = this.options.find(function (t) {
                  return t.element.value === e.selected;
                });
              return this.id ? this.id + "=" + t.element.value : "";
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    let n,
      o = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      i = r(2),
      a = (n = i) && n.__esModule ? n : { default: n };
    const l = (function (e) {
      function t(e, r) {
        const n =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : [],
          o =
            arguments.length > 3 && void 0 !== arguments[3]
              ? arguments[3]
              : null;
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, t);
        const i = (function (e, t) {
          if (!e)
            throw new ReferenceError(
              "this hasn't been initialised - super() hasn't been called"
            );
          return !t || ("object" != typeof t && "function" != typeof t) ? e : t;
        })(
          this,
          (t.__proto__ || Object.getPrototypeOf(t)).call(this, e, r, n, o)
        );
        return (i.group = e), (i.name = r), (i.checkboxes = []), i;
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, a.default),
        o(t, [
          {
            key: "addControl",
            value: function (e) {
              const r = this,
                n = (function e(t, r, n) {
                  null === t && (t = Function.prototype);
                  const o = Object.getOwnPropertyDescriptor(t, r);
                  if (void 0 === o) {
                    const i = Object.getPrototypeOf(t);
                    return null === i ? void 0 : e(i, r, n);
                  }
                  if ("value" in o) return o.value;
                  const a = o.get;
                  return void 0 !== a ? a.call(n) : void 0;
                })(
                  t.prototype.__proto__ || Object.getPrototypeOf(t.prototype),
                  "addControl",
                  this
                ).call(this, e);
              if (((n.selected = n.element.checked), n.id)) {
                const o = this.deepLinkParams.find(function (e) {
                  return e.key === n.id;
                });
                o && (n.selected = "1" === o.value);
              }
              this.checkboxes.push(n),
                this.handleCheckboxes(),
                n.element.addEventListener("change", function (e) {
                  e.preventDefault(),
                    (n.selected = !n.selected),
                    r.checkboxes.forEach(function (e) {
                      e.isEqualTo(n) && (e.selected = n.selected);
                    }),
                    r.handleCheckboxes(),
                    window.jplist && window.jplist.refresh(r.group, n);
                });
            },
          },
          {
            key: "handleCheckboxes",
            value: function () {
              let e = !0,
                t = !1,
                r = void 0;
              try {
                for (
                  var n, o = this.checkboxes[Symbol.iterator]();
                  !(e = (n = o.next()).done);
                  e = !0
                ) {
                  const i = n.value;
                  i.selected
                    ? i.element.classList.add("jplist-selected")
                    : i.element.classList.remove("jplist-selected"),
                    (i.element.checked = i.selected);
                }
              } catch (e) {
                (t = !0), (r = e);
              } finally {
                try {
                  !e && o.return && o.return();
                } finally {
                  if (t) throw r;
                }
              }
            },
          },
          {
            key: "getSortOptions",
            value: function () {
              let e = [],
                t = !1,
                r = !0,
                n = !1,
                o = void 0;
              try {
                for (
                  var i, a = this.checkboxes[Symbol.iterator]();
                  !(r = (i = a.next()).done);
                  r = !0
                ) {
                  const l = i.value;
                  l.selected ? (e = e.concat(l.getSortOptions())) : (t = !0);
                }
              } catch (e) {
                (n = !0), (o = e);
              } finally {
                try {
                  !r && a.return && a.return();
                } finally {
                  if (n) throw o;
                }
              }
              return t && (e = e.concat([{ path: "default" }])), e;
            },
          },
          {
            key: "getDeepLink",
            value: function () {
              const e = this.checkboxes
                .map(function (e) {
                  return e.id ? (e.selected ? e.id + "=1" : e.id + "=0") : "";
                })
                .filter(function (e) {
                  return "" !== e;
                });
              return Array.from(new Set(e)).join("&");
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    let n,
      o = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      i = r(2),
      a = (n = i) && n.__esModule ? n : { default: n };
    const l = (function (e) {
      function t(e, r) {
        const n =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : [],
          o =
            arguments.length > 3 && void 0 !== arguments[3]
              ? arguments[3]
              : null;
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, t);
        const i = (function (e, t) {
          if (!e)
            throw new ReferenceError(
              "this hasn't been initialised - super() hasn't been called"
            );
          return !t || ("object" != typeof t && "function" != typeof t) ? e : t;
        })(
          this,
          (t.__proto__ || Object.getPrototypeOf(t)).call(this, e, r, n, o)
        );
        return (i.group = e), (i.name = r), (i.radios = []), i;
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, a.default),
        o(t, [
          {
            key: "addControl",
            value: function (e) {
              const r = this,
                n = (function e(t, r, n) {
                  null === t && (t = Function.prototype);
                  const o = Object.getOwnPropertyDescriptor(t, r);
                  if (void 0 === o) {
                    const i = Object.getPrototypeOf(t);
                    return null === i ? void 0 : e(i, r, n);
                  }
                  if ("value" in o) return o.value;
                  const a = o.get;
                  return void 0 !== a ? a.call(n) : void 0;
                })(
                  t.prototype.__proto__ || Object.getPrototypeOf(t.prototype),
                  "addControl",
                  this
                ).call(this, e);
              if (((n.selected = n.element.checked), n.id)) {
                const o = this.deepLinkParams.find(function (e) {
                  return e.key === n.id;
                });
                o && (n.selected = "1" === o.value);
              }
              this.radios.push(n),
                this.handleRadios(),
                n.element.addEventListener("change", function (e) {
                  e.preventDefault();
                  let t = !0,
                    o = !1,
                    i = void 0;
                  try {
                    for (
                      var a, l = r.radios[Symbol.iterator]();
                      !(t = (a = l.next()).done);
                      t = !0
                    ) {
                      a.value.selected = !1;
                    }
                  } catch (e) {
                    (o = !0), (i = e);
                  } finally {
                    try {
                      !t && l.return && l.return();
                    } finally {
                      if (o) throw i;
                    }
                  }
                  (n.selected = !0),
                    r.handleRadios(),
                    window.jplist && window.jplist.refresh(r.group, n);
                });
            },
          },
          {
            key: "getLastSelectedRadio",
            value: function () {
              let e = null,
                t = !0,
                r = !1,
                n = void 0;
              try {
                for (
                  var o, i = this.radios[Symbol.iterator]();
                  !(t = (o = i.next()).done);
                  t = !0
                ) {
                  const a = o.value;
                  a.selected && (e = a);
                }
              } catch (e) {
                (r = !0), (n = e);
              } finally {
                try {
                  !t && i.return && i.return();
                } finally {
                  if (r) throw n;
                }
              }
              return e;
            },
          },
          {
            key: "handleRadios",
            value: function () {
              if (this.radios.length > 0) {
                let e = this.getLastSelectedRadio(),
                  t = !0,
                  r = !1,
                  n = void 0;
                try {
                  for (
                    var o, i = this.radios[Symbol.iterator]();
                    !(t = (o = i.next()).done);
                    t = !0
                  ) {
                    const a = o.value;
                    (a.selected = !1),
                      a.element.classList.remove("jplist-selected");
                  }
                } catch (e) {
                  (r = !0), (n = e);
                } finally {
                  try {
                    !t && i.return && i.return();
                  } finally {
                    if (r) throw n;
                  }
                }
                e &&
                  this.radios.forEach(function (t) {
                    t.isEqualTo(e) &&
                      ((t.selected = !0),
                      (t.element.checked = !0),
                      t.element.classList.add("jplist-selected"));
                  });
              }
            },
          },
          {
            key: "getSortOptions",
            value: function () {
              let e = [],
                t = this.getLastSelectedRadio();
              return t && (e = e.concat(t.getSortOptions())), e;
            },
          },
          {
            key: "getDeepLink",
            value: function () {
              const e = this.radios
                .map(function (e) {
                  return e.id && e.selected ? e.id + "=1" : "";
                })
                .filter(function (e) {
                  return "" !== e;
                });
              return Array.from(new Set(e)).join("&");
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    let n,
      o = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      i = r(2),
      a = (n = i) && n.__esModule ? n : { default: n };
    const l = (function (e) {
      function t(e, r) {
        const n =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : [],
          o =
            arguments.length > 3 && void 0 !== arguments[3]
              ? arguments[3]
              : null;
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, t);
        const i = (function (e, t) {
          if (!e)
            throw new ReferenceError(
              "this hasn't been initialised - super() hasn't been called"
            );
          return !t || ("object" != typeof t && "function" != typeof t) ? e : t;
        })(
          this,
          (t.__proto__ || Object.getPrototypeOf(t)).call(this, e, r, n, o)
        );
        return (
          (i.group = e), (i.name = r), (i.checkboxes = []), (i.radios = []), i
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, a.default),
        o(t, [
          {
            key: "addControl",
            value: function (e) {
              const r = this,
                n = (function e(t, r, n) {
                  null === t && (t = Function.prototype);
                  const o = Object.getOwnPropertyDescriptor(t, r);
                  if (void 0 === o) {
                    const i = Object.getPrototypeOf(t);
                    return null === i ? void 0 : e(i, r, n);
                  }
                  if ("value" in o) return o.value;
                  const a = o.get;
                  return void 0 !== a ? a.call(n) : void 0;
                })(
                  t.prototype.__proto__ || Object.getPrototypeOf(t.prototype),
                  "addControl",
                  this
                ).call(this, e);
              if (
                ((n.selected =
                  "true" === n.element.getAttribute("data-selected")),
                (n.mode = n.element.getAttribute("data-mode") || "radio"),
                n.id)
              ) {
                const o = this.deepLinkParams.find(function (e) {
                  return e.key === n.id;
                });
                o && (n.selected = "1" === o.value);
              }
              "radio" === n.mode && (this.radios.push(n), this.handleRadios()),
                "checkbox" === n.mode &&
                  (this.checkboxes.push(n), this.handleCheckboxes()),
                n.element.addEventListener("click", function (e) {
                  if (
                    (e.preventDefault(),
                    "checkbox" === n.mode &&
                      ((n.selected = !n.selected),
                      r.checkboxes.forEach(function (e) {
                        e.isEqualTo(n) && (e.selected = n.selected);
                      }),
                      r.handleCheckboxes()),
                    "radio" === n.mode)
                  ) {
                    let t = !0,
                      o = !1,
                      i = void 0;
                    try {
                      for (
                        var a, l = r.radios[Symbol.iterator]();
                        !(t = (a = l.next()).done);
                        t = !0
                      ) {
                        a.value.selected = !1;
                      }
                    } catch (e) {
                      (o = !0), (i = e);
                    } finally {
                      try {
                        !t && l.return && l.return();
                      } finally {
                        if (o) throw i;
                      }
                    }
                    (n.selected = !0), r.handleRadios();
                  }
                  window.jplist && window.jplist.refresh(r.group, n);
                });
            },
          },
          {
            key: "handleCheckboxes",
            value: function () {
              let e = !0,
                t = !1,
                r = void 0;
              try {
                for (
                  var n, o = this.checkboxes[Symbol.iterator]();
                  !(e = (n = o.next()).done);
                  e = !0
                ) {
                  const i = n.value;
                  i.selected
                    ? i.element.classList.add("jplist-selected")
                    : i.element.classList.remove("jplist-selected");
                }
              } catch (e) {
                (t = !0), (r = e);
              } finally {
                try {
                  !e && o.return && o.return();
                } finally {
                  if (t) throw r;
                }
              }
            },
          },
          {
            key: "getLastSelectedRadio",
            value: function () {
              let e = null,
                t = !0,
                r = !1,
                n = void 0;
              try {
                for (
                  var o, i = this.radios[Symbol.iterator]();
                  !(t = (o = i.next()).done);
                  t = !0
                ) {
                  const a = o.value;
                  a.selected && (e = a);
                }
              } catch (e) {
                (r = !0), (n = e);
              } finally {
                try {
                  !t && i.return && i.return();
                } finally {
                  if (r) throw n;
                }
              }
              return e;
            },
          },
          {
            key: "handleRadios",
            value: function () {
              if (this.radios.length > 0) {
                let e = this.getLastSelectedRadio(),
                  t = !0,
                  r = !1,
                  n = void 0;
                try {
                  for (
                    var o, i = this.radios[Symbol.iterator]();
                    !(t = (o = i.next()).done);
                    t = !0
                  ) {
                    const a = o.value;
                    (a.selected = !1),
                      a.element.classList.remove("jplist-selected");
                  }
                } catch (e) {
                  (r = !0), (n = e);
                } finally {
                  try {
                    !t && i.return && i.return();
                  } finally {
                    if (r) throw n;
                  }
                }
                e &&
                  this.radios.forEach(function (t) {
                    t.isEqualTo(e) &&
                      ((t.selected = !0),
                      (t.element.checked = !0),
                      t.element.classList.add("jplist-selected"));
                  });
              }
            },
          },
          {
            key: "getSortOptions",
            value: function () {
              let e = [],
                t = !1,
                r = !0,
                n = !1,
                o = void 0;
              try {
                for (
                  var i, a = this.checkboxes[Symbol.iterator]();
                  !(r = (i = a.next()).done);
                  r = !0
                ) {
                  const l = i.value;
                  l.selected ? (e = e.concat(l.getSortOptions())) : (t = !0);
                }
              } catch (e) {
                (n = !0), (o = e);
              } finally {
                try {
                  !r && a.return && a.return();
                } finally {
                  if (n) throw o;
                }
              }
              t && (e = e.concat([{ path: "default" }]));
              const u = this.getLastSelectedRadio();
              return u && (e = e.concat(u.getSortOptions())), e;
            },
          },
          {
            key: "getDeepLink",
            value: function () {
              const e = this.checkboxes
                  .map(function (e) {
                    return e.id && e.selected ? e.id + "=1" : "";
                  })
                  .filter(function (e) {
                    return "" !== e;
                  }),
                t = this.radios
                  .map(function (e) {
                    return e.id && e.selected ? e.id + "=1" : "";
                  })
                  .filter(function (e) {
                    return "" !== e;
                  }),
                r = e.concat(t);
              return Array.from(new Set(r)).join("&");
            },
          },
        ]),
        t
      );
    })();
    t.default = l;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    let n,
      o = r(2),
      i = (n = o) && n.__esModule ? n : { default: n };
    const a = (function (e) {
      function t() {
        return (
          (function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, t),
          (function (e, t) {
            if (!e)
              throw new ReferenceError(
                "this hasn't been initialised - super() hasn't been called"
              );
            return !t || ("object" != typeof t && "function" != typeof t)
              ? e
              : t;
          })(
            this,
            (t.__proto__ || Object.getPrototypeOf(t)).apply(this, arguments)
          )
        );
      }
      return (
        (function (e, t) {
          if ("function" != typeof t && null !== t)
            throw new TypeError(
              "Super expression must either be null or a function, not " +
                typeof t
            );
          (e.prototype = Object.create(t && t.prototype, {
            constructor: {
              value: e,
              enumerable: !1,
              writable: !0,
              configurable: !0,
            },
          })),
            t &&
              (Object.setPrototypeOf
                ? Object.setPrototypeOf(e, t)
                : (e.__proto__ = t));
        })(t, i.default),
        t
      );
    })();
    t.default = a;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
      function e(e, t) {
        for (let r = 0; r < t.length; r++) {
          const n = t[r];
          (n.enumerable = n.enumerable || !1),
            (n.configurable = !0),
            "value" in n && (n.writable = !0),
            Object.defineProperty(e, n.key, n);
        }
      }
      return function (t, r, n) {
        return r && e(t.prototype, r), n && e(t, n), t;
      };
    })();
    const o = (function () {
      function e() {
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, e);
      }
      return (
        n(e, null, [
          {
            key: "isSupported",
            value: function (e) {
              if ("cookies" === e) return !0;
              try {
                return e in window && null !== window[e];
              } catch (e) {
                return !1;
              }
            },
          },
          {
            key: "set",
            value: function (t, r, n) {
              let o =
                arguments.length > 3 && void 0 !== arguments[3]
                  ? arguments[3]
                  : -1;
              if ("cookies" === r) {
                const i = encodeURIComponent(t),
                  a = new Date();
                -1 === (o = Number(o) || -1)
                  ? (document.cookie = name + "=" + i + ";path=/;")
                  : (a.setMinutes(a.getMinutes() + o),
                    (document.cookie =
                      name + "=" + i + ";path=/; expires=" + a.toUTCString()));
              } else e.isSupported(r) && (window[r][n] = t);
            },
          },
          {
            key: "get",
            value: function (t, r) {
              let n = "";
              if ("cookies" === t)
                for (
                  let o = document.cookie.split(";"), i = 0;
                  i < o.length;
                  i++
                ) {
                  let a = o[i].substr(0, o[i].indexOf("=")),
                    l = o[i].substr(o[i].indexOf("=") + 1);
                  if ((a = a.replace(/^\s+|\s+$/g, "")) === r) {
                    n = decodeURIComponent(l);
                    break;
                  }
                }
              else e.isSupported(t) && (n = window[t][r] || "");
              return n;
            },
          },
        ]),
        e
      );
    })();
    t.default = o;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
      function e(e, t) {
        for (let r = 0; r < t.length; r++) {
          const n = t[r];
          (n.enumerable = n.enumerable || !1),
            (n.configurable = !0),
            "value" in n && (n.writable = !0),
            Object.defineProperty(e, n.key, n);
        }
      }
      return function (t, r, n) {
        return r && e(t.prototype, r), n && e(t, n), t;
      };
    })();
    const o = (function () {
      function e() {
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, e);
      }
      return (
        n(e, null, [
          {
            key: "getParam",
            value: function (e) {
              if (!e) return null;
              const t = e.split("=");
              return t.length < 2
                ? null
                : {
                    key: t[0].trim().toLowerCase(),
                    value: t[1].trim().toLowerCase(),
                  };
            },
          },
          {
            key: "getUrlParams",
            value: function (t) {
              const r =
                  arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : "#",
                n = new Map();
              if (!t) return n;
              const o = window
                .decodeURIComponent(t.replace(r, ""))
                .trim()
                .toLowerCase();
              if (!o) return n;
              let i = o.split("&"),
                a = "",
                l = !0,
                u = !1,
                s = void 0;
              try {
                for (
                  var c, f = i[Symbol.iterator]();
                  !(l = (c = f.next()).done);
                  l = !0
                ) {
                  const d = c.value,
                    p = e.getParam(d);
                  if (p)
                    if ("group" === p.key)
                      (a = p.value), n.has(p.value) || n.set(p.value, []);
                    else {
                      const h = n.get(a);
                      h && h.push(p), n.set(a, h);
                    }
                }
              } catch (e) {
                (u = !0), (s = e);
              } finally {
                try {
                  !l && f.return && f.return();
                } finally {
                  if (u) throw s;
                }
              }
              return n;
            },
          },
        ]),
        e
      );
    })();
    t.default = o;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    t.default = function e(t, r, n, o) {
      !(function (e, t) {
        if (!(e instanceof t))
          throw new TypeError("Cannot call a class as a function");
      })(this, e),
        (this.itemsNumber = Number(n) || 0),
        (this.itemsPerPage = Number.isInteger(r)
          ? Number(r)
          : this.itemsNumber),
        0 === this.itemsPerPage && (this.itemsPerPage = n),
        (this.pagesNumber =
          0 === this.itemsPerPage
            ? 0
            : Math.ceil(this.itemsNumber / this.itemsPerPage)),
        (this.currentPage = Number(t) || 0),
        this.currentPage > this.pagesNumber - 1 && (this.currentPage = 0),
        (this.start = this.currentPage * this.itemsPerPage),
        (this.end = this.start + this.itemsPerPage),
        this.end > this.itemsNumber && (this.end = this.itemsNumber),
        (this.prevPage = this.currentPage <= 0 ? 0 : this.currentPage - 1),
        (this.nextPage =
          0 === this.pagesNumber
            ? 0
            : this.currentPage >= this.pagesNumber - 1
            ? this.pagesNumber - 1
            : this.currentPage + 1),
        (this.range = Number(o) || 10);
      const i = Math.ceil((this.range - 1) / 2);
      (this.rangeStart = this.currentPage - i),
        (this.rangeEnd = Math.min(
          this.rangeStart + this.range - 1,
          this.pagesNumber - 1
        )),
        this.rangeStart <= 0 &&
          ((this.rangeStart = 0),
          (this.rangeEnd = Math.min(this.range - 1, this.pagesNumber - 1))),
        this.rangeEnd >= this.pagesNumber - 1 &&
          ((this.rangeStart = Math.max(this.pagesNumber - this.range, 0)),
          (this.rangeEnd = this.pagesNumber - 1));
    };
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
      function e(e, t) {
        for (let r = 0; r < t.length; r++) {
          const n = t[r];
          (n.enumerable = n.enumerable || !1),
            (n.configurable = !0),
            "value" in n && (n.writable = !0),
            Object.defineProperty(e, n.key, n);
        }
      }
      return function (t, r, n) {
        return r && e(t.prototype, r), n && e(t, n), t;
      };
    })();
    const o = (function () {
      function e() {
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, e);
      }
      return (
        n(e, null, [
          {
            key: "sort",
            value: function (t, r) {
              return !r || r.length <= 0
                ? (t.sort(function (t, r) {
                    return e.sortByIndex(t, r);
                  }),
                  t)
                : (t.sort(function (t, n) {
                    return e.sortHelper(t, n, r, 0);
                  }),
                  t);
            },
          },
          {
            key: "sortHelper",
            value: function (t, r, n, o) {
              if (!n || n.length <= 0 || o >= n.length) return 0;
              let i = 0,
                a = n[o];
              if ("default" !== a.path)
                switch (a.dataType) {
                  case "number":
                    i = e.sortNumbers(t, r, a.path, a.order);
                    break;
                  case "datetime":
                    i = e.sortDateTime(t, r, a.path, a.order, a.dateTimeFormat);
                    break;
                  default:
                    i = e.sortText(t, r, a.path, a.order, a.ignoreRegex);
                }
              else i = e.sortByIndex(t, r);
              return (
                0 === i &&
                  o + 1 < n.length &&
                  (i = e.sortHelper(t, r, n, o + 1)),
                i
              );
            },
          },
          {
            key: "sortText",
            value: function (e, t) {
              let r =
                  arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : "",
                n =
                  arguments.length > 3 && void 0 !== arguments[3]
                    ? arguments[3]
                    : "asc",
                o =
                  arguments.length > 4 && void 0 !== arguments[4]
                    ? arguments[4]
                    : "";
              if (!e || !t) return 0;
              const i = r ? e.querySelector(r) : e,
                a = r ? t.querySelector(r) : t;
              if (!i || !a) return 0;
              let l = i.textContent.trim().toLowerCase(),
                u = a.textContent.trim().toLowerCase();
              if (o) {
                const s = new RegExp(o, "ig");
                (l = l.replace(s, "").trim()), (u = u.replace(s, "").trim());
              }
              return l === u
                ? 0
                : (n || (n = "asc"),
                  "".localeCompare
                    ? "asc" === n
                      ? l.localeCompare(u)
                      : u.localeCompare(l)
                    : "asc" === n
                    ? l > u
                      ? 1
                      : -1
                    : l < u
                    ? 1
                    : -1);
            },
          },
          {
            key: "sortNumbers",
            value: function (e, t) {
              let r =
                  arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : "",
                n =
                  arguments.length > 3 && void 0 !== arguments[3]
                    ? arguments[3]
                    : "asc";
              if (!e || !t) return 0;
              const o = r ? e.querySelector(r) : e,
                i = r ? t.querySelector(r) : t;
              if (!o || !i) return 0;
              let a = o.textContent.trim().toLowerCase(),
                l = i.textContent.trim().toLowerCase();
              return (
                (a = parseFloat(a.replace(/[^-0-9.]+/g, ""))),
                (l = parseFloat(l.replace(/[^-0-9.]+/g, ""))),
                isNaN(a) || isNaN(l)
                  ? isNaN(a) && isNaN(l)
                    ? 0
                    : isNaN(a)
                    ? 1
                    : -1
                  : a === l
                  ? 0
                  : (n || (n = "asc"), "asc" === n ? a - l : l - a)
              );
            },
          },
          {
            key: "sortByIndex",
            value: function (e, t) {
              if (!e || !t) return 0;
              const r = Number(e.jplistIndex),
                n = Number(t.jplistIndex);
              return isNaN(r) || isNaN(n) ? 0 : r - n;
            },
          },
          {
            key: "sortDateTime",
            value: function (t, r) {
              let n =
                  arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : "",
                o =
                  arguments.length > 3 && void 0 !== arguments[3]
                    ? arguments[3]
                    : "asc",
                i =
                  arguments.length > 4 && void 0 !== arguments[4]
                    ? arguments[4]
                    : "";
              if (!t || !r) return 0;
              const a = n ? t.querySelector(n) : t,
                l = n ? r.querySelector(n) : r;
              if (!a || !l) return 0;
              let u = a.textContent.trim().toLowerCase(),
                s = l.textContent.trim().toLowerCase(),
                c = void 0,
                f = void 0;
              return (
                (i = i.trim())
                  ? ((c = e.getDateFromString(u, i)),
                    (f = e.getDateFromString(s, i)))
                  : ((c = new Date(Date.parse(u))),
                    (f = new Date(Date.parse(s)))),
                c.getTime() === f.getTime()
                  ? 0
                  : (o || (o = "asc"),
                    "asc" === o
                      ? c.getTime() > f.getTime()
                        ? 1
                        : -1
                      : c.getTime() < f.getTime()
                      ? 1
                      : -1)
              );
            },
          },
          {
            key: "getDateFromString",
            value: function (t, r) {
              r = (r = (r = (r = (r = r.replace(/\./g, "\\.")).replace(
                /\(/g,
                "\\("
              )).replace(/\)/g, "\\)")).replace(/\[/g, "\\[")).replace(
                /\]/g,
                "\\]"
              );
              let n = e.getDateWildcardValue(r, "{year}", t);
              n = Number(n) || 1900;
              let o = e.getDateWildcardValue(r, "{day}", t);
              o = Number(o) || 1;
              let i = e.getDateWildcardValue(r, "{month}", t);
              -1 === (i = e.getMonthByWildcard(i)) && (i = 0);
              let a = e.getDateWildcardValue(r, "{hour}", t);
              a = Number(a) || 0;
              let l = e.getDateWildcardValue(r, "{min}", t);
              l = Number(l) || 0;
              let u = e.getDateWildcardValue(r, "{sec}", t);
              return (u = Number(u) || 0), new Date(n, i, o, a, l, u);
            },
          },
          {
            key: "getDateWildcardValue",
            value: function (e, t, r) {
              let n = null,
                o = e
                  .replace(t, "(.*)")
                  .replace(/{year}|{month}|{day}|{hour}|{min}|{sec}/g, ".*"),
                i = new RegExp(o, "g").exec(r);
              return i && i.length > 1 && (n = i[1]), n;
            },
          },
          {
            key: "getMonthByWildcard",
            value: function (t) {
              t = t ? t.trim().toLowerCase() : "";
              const r = Number(t);
              return isNaN(r)
                ? e.months.findIndex(function (e) {
                    return e.find(function (e) {
                      return e.trim() === t;
                    });
                  })
                : r - 1 < 0
                ? -1
                : r - 1;
            },
          },
          {
            key: "months",
            get: function () {
              return [
                ["january", "jan", "jan."],
                ["february", "feb", "feb."],
                ["march", "mar", "mar."],
                ["april", "apr", "apr."],
                ["may"],
                ["june", "jun."],
                ["july", "jul", "jul."],
                ["august", "aug", "aug."],
                ["september", "sep", "sep."],
                ["october", "oct", "oct."],
                ["november", "nov", "nov."],
                ["december", "dec", "dec."],
              ];
            },
          },
        ]),
        e
      );
    })();
    t.default = o;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        return function (e, t) {
          if (Array.isArray(e)) return e;
          if (Symbol.iterator in Object(e))
            return (function (e, t) {
              let r = [],
                n = !0,
                o = !1,
                i = void 0;
              try {
                for (
                  var a, l = e[Symbol.iterator]();
                  !(n = (a = l.next()).done) &&
                  (r.push(a.value), !t || r.length !== t);
                  n = !0
                );
              } catch (e) {
                (o = !0), (i = e);
              } finally {
                try {
                  !n && l.return && l.return();
                } finally {
                  if (o) throw i;
                }
              }
              return r;
            })(e, t);
          throw new TypeError(
            "Invalid attempt to destructure non-iterable instance"
          );
        };
      })(),
      o = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      i = f(r(43)),
      a = f(r(42)),
      l = f(r(1)),
      u = f(r(41)),
      s = f(r(40)),
      c = f(r(14));
    function f(e) {
      return e && e.__esModule ? e : { default: e };
    }
    function d(e) {
      if (Array.isArray(e)) {
        for (var t = 0, r = Array(e.length); t < e.length; t++) r[t] = e[t];
        return r;
      }
      return Array.from(e);
    }
    const p = (function () {
      function e() {
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, e);
      }
      return (
        o(e, null, [
          {
            key: "apply",
            value: function (t, r, o) {
              const l =
                  arguments.length > 3 && void 0 !== arguments[3]
                    ? arguments[3]
                    : "",
                u =
                  arguments.length > 4 && void 0 !== arguments[4]
                    ? arguments[4]
                    : void 0;
              if (r && o) {
                let c = [
                    { options: "pathFilterOptions", name: "pathFilter" },
                    { options: "rangeFilterOptions", name: "rangeFilter" },
                    { options: "textFilterOptions", name: "textFilter" },
                  ],
                  f = !0,
                  d = !1,
                  p = void 0;
                try {
                  for (
                    var h, v = o[Symbol.iterator]();
                    !(f = (h = v.next()).done);
                    f = !0
                  ) {
                    const y = n(h.value, 2),
                      b = y[0],
                      m = y[1];
                    if ((l && l === b) || !l) {
                      let g = r.get(b),
                        w = e.collectControlsOptions(g),
                        O = !0,
                        _ = !1,
                        j = void 0;
                      try {
                        for (
                          var P, k = m[Symbol.iterator]();
                          !(O = (P = k.next()).done);
                          O = !0
                        ) {
                          let x = P.value,
                            S = x.items.length,
                            C = e.getItemsFragment(x.items);
                          w.sortOptions &&
                            w.sortOptions.length > 0 &&
                            (i.default.sort(x.items, w.sortOptions),
                            (C = e.getItemsFragment(x.items)));
                          let E = x.items,
                            L = !0,
                            T = !1,
                            A = void 0;
                          try {
                            for (
                              var N, M = c[Symbol.iterator]();
                              !(L = (N = M.next()).done);
                              L = !0
                            ) {
                              const F = N.value,
                                D = F.options;
                              if (w[D]) {
                                const R = e.splitByLogic(w[D]);
                                for (const B in ((E = e.handleFilter(
                                  E,
                                  R.and,
                                  "and",
                                  F.name
                                )),
                                R.or))
                                  E = e.handleFilter(E, R.or[B], "or", F.name);
                                (S = E.length), (C = e.getItemsFragment(E));
                              }
                            }
                          } catch (e) {
                            (T = !0), (A = e);
                          } finally {
                            try {
                              !L && M.return && M.return();
                            } finally {
                              if (T) throw A;
                            }
                          }
                          if (w.paginationOptions) {
                            const q = new a.default(
                              w.paginationOptions.currentPage,
                              w.paginationOptions.itemsPerPage,
                              E.length,
                              w.paginationOptions.range
                            );
                            if (g.length > 0) {
                              let I = !0,
                                H = !1,
                                V = void 0;
                              try {
                                for (
                                  var W, G = g[Symbol.iterator]();
                                  !(I = (W = G.next()).done);
                                  I = !0
                                ) {
                                  const z = W.value;
                                  z.setPaginationOptions &&
                                    z.setPaginationOptions(q);
                                }
                              } catch (e) {
                                (H = !0), (V = e);
                              } finally {
                                try {
                                  !I && G.return && G.return();
                                } finally {
                                  if (H) throw V;
                                }
                              }
                            }
                            const U = E.slice(q.start, q.end);
                            (S = U.length), (C = e.getItemsFragment(U));
                          }
                          x.root.appendChild(C),
                            e.sendStateEvent(w, S, g, o, E);
                        }
                      } catch (e) {
                        (_ = !0), (j = e);
                      } finally {
                        try {
                          !O && k.return && k.return();
                        } finally {
                          if (_) throw j;
                        }
                      }
                      e.jump(g, u);
                    }
                  }
                } catch (e) {
                  (d = !0), (p = e);
                } finally {
                  try {
                    !f && v.return && v.return();
                  } finally {
                    if (d) throw p;
                  }
                }
                t.deepLinking
                  ? e.updateDeepLink(e.getDeepLink(r, o), t.hashStart)
                  : t.storage &&
                    s.default.set(
                      e.getDeepLink(r, o),
                      t.storage,
                      t.storageName,
                      t.cookiesExpiration
                    );
              }
            },
          },
          {
            key: "performFilter",
            value: function (e, t, r) {
              switch (r) {
                case "textFilter":
                  return c.default.textFilter(
                    t,
                    e.text,
                    e.path,
                    e.mode,
                    e.ignoreRegex
                  );
                case "pathFilter":
                  return c.default.pathFilter(t, e.path, e.isInverted);
                case "rangeFilter":
                  return c.default.rangeFilter(
                    t,
                    e.path,
                    e.from,
                    e.to,
                    e.min,
                    e.max
                  );
              }
              return t;
            },
          },
          {
            key: "handleFilter",
            value: function (t, r, n, o) {
              if (r.length <= 0) return t;
              if ("and" === n) {
                let i = !0,
                  a = !1,
                  l = void 0;
                try {
                  for (
                    var u, s = r[Symbol.iterator]();
                    !(i = (u = s.next()).done);
                    i = !0
                  ) {
                    const c = u.value;
                    t = e.performFilter(c, t, o);
                  }
                } catch (e) {
                  (a = !0), (l = e);
                } finally {
                  try {
                    !i && s.return && s.return();
                  } finally {
                    if (a) throw l;
                  }
                }
              }
              if ("or" === n) {
                let f = new Set(),
                  p = !0,
                  h = !1,
                  v = void 0;
                try {
                  for (
                    var y, b = r[Symbol.iterator]();
                    !(p = (y = b.next()).done);
                    p = !0
                  ) {
                    const m = y.value,
                      g = e.performFilter(m, t, o);
                    f = new Set([].concat(d(f), d(g)));
                  }
                } catch (e) {
                  (h = !0), (v = e);
                } finally {
                  try {
                    !p && b.return && b.return();
                  } finally {
                    if (h) throw v;
                  }
                }
                t = Array.from(f);
              }
              return t;
            },
          },
          {
            key: "splitByLogic",
            value: function (e) {
              let t = { and: [], or: {} },
                r = !0,
                n = !1,
                o = void 0;
              try {
                for (
                  var i, a = e[Symbol.iterator]();
                  !(r = (i = a.next()).done);
                  r = !0
                ) {
                  const l = i.value,
                    u = l.or;
                  u
                    ? void 0 === t.or[u]
                      ? (t.or[u] = [l])
                      : t.or[u].push(l)
                    : t.and.push(l);
                }
              } catch (e) {
                (n = !0), (o = e);
              } finally {
                try {
                  !r && a.return && a.return();
                } finally {
                  if (n) throw o;
                }
              }
              return t;
            },
          },
          {
            key: "jump",
            value: function (e) {
              const t =
                arguments.length > 1 && void 0 !== arguments[1]
                  ? arguments[1]
                  : void 0;
              if (t && t.jump) {
                let r = -1;
                if ("top" === t.jump) r = 0;
                else {
                  const n = document.querySelector(t.jump);
                  if (!n) return;
                  const o = n.getBoundingClientRect();
                  if (!n.width && !n.height && !n.getClientRects().length)
                    return;
                  const i = document.clientTop || document.body.clientTop || 0;
                  r < 0
                    ? (r = o.top + window.pageYOffset - i)
                    : o.top + window.pageYOffset - i < r &&
                      (r = o.top + window.pageYOffset - i);
                }
                r >= 0 && window.scroll(0, r);
              }
            },
          },
          {
            key: "sendStateEvent",
            value: function (e, t, r, n, o) {
              if (r) {
                const i = new CustomEvent("jplist.state");
                i.jplistState = {
                  options: e,
                  itemsNumber: t,
                  groups: n,
                  filtered: o,
                };
                let a = !0,
                  l = !1,
                  u = void 0;
                try {
                  for (
                    var s, c = r[Symbol.iterator]();
                    !(a = (s = c.next()).done);
                    a = !0
                  ) {
                    let f = s.value,
                      d = !0,
                      p = !1,
                      h = void 0;
                    try {
                      for (
                        var v, y = f.controls[Symbol.iterator]();
                        !(d = (v = y.next()).done);
                        d = !0
                      ) {
                        v.value.element.dispatchEvent(i);
                      }
                    } catch (e) {
                      (p = !0), (h = e);
                    } finally {
                      try {
                        !d && y.return && y.return();
                      } finally {
                        if (p) throw h;
                      }
                    }
                  }
                } catch (e) {
                  (l = !0), (u = e);
                } finally {
                  try {
                    !a && c.return && c.return();
                  } finally {
                    if (l) throw u;
                  }
                }
              }
            },
          },
          {
            key: "collectControlsOptions",
            value: function (e) {
              const t = {
                sortOptions: [],
                paginationOptions: null,
                textFilterOptions: [],
                pathFilterOptions: [],
                rangeFilterOptions: [],
              };
              if (!e) return t;
              let r = !0,
                n = !1,
                o = void 0;
              try {
                for (
                  var i, a = e[Symbol.iterator]();
                  !(r = (i = a.next()).done);
                  r = !0
                ) {
                  const l = i.value;
                  l.getSortOptions &&
                    (t.sortOptions = t.sortOptions.concat(l.getSortOptions())),
                    l.getTextFilterOptions &&
                      (t.textFilterOptions = t.textFilterOptions.concat(
                        l.getTextFilterOptions()
                      )),
                    l.getPathFilterOptions &&
                      (t.pathFilterOptions = t.pathFilterOptions.concat(
                        l.getPathFilterOptions()
                      )),
                    l.getRangeFilterOptions &&
                      (t.rangeFilterOptions = t.rangeFilterOptions.concat(
                        l.getRangeFilterOptions()
                      )),
                    l.getPaginationOptions &&
                      (t.paginationOptions = l.getPaginationOptions());
                }
              } catch (e) {
                (n = !0), (o = e);
              } finally {
                try {
                  !r && a.return && a.return();
                } finally {
                  if (n) throw o;
                }
              }
              return t;
            },
          },
          {
            key: "getItemsFragment",
            value: function (e) {
              let t = document.createDocumentFragment(),
                r = !0,
                n = !1,
                o = void 0;
              try {
                for (
                  var i, a = e[Symbol.iterator]();
                  !(r = (i = a.next()).done);
                  r = !0
                ) {
                  const l = i.value;
                  t.appendChild(l);
                }
              } catch (e) {
                (n = !0), (o = e);
              } finally {
                try {
                  !r && a.return && a.return();
                } finally {
                  if (n) throw o;
                }
              }
              return t;
            },
          },
          {
            key: "updateDeepLink",
            value: function (e) {
              let t =
                  arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : "#",
                r = e.replace(t, "").trim();
              if (((r = "" === r ? t : t + r), window.location.hash !== r)) {
                let n = window.location.href.indexOf(t),
                  o = void 0;
                (o =
                  -1 === n
                    ? window.location.href + r
                    : window.location.href.substring(0, n) + r),
                  "replaceState" in window.history
                    ? window.history.replaceState("", "", o)
                    : window.location.replace(o);
              }
            },
          },
          {
            key: "getDeepLink",
            value: function (e, t) {
              let r = [],
                n = !0,
                o = !1,
                i = void 0;
              try {
                for (
                  var a, l = t.keys()[Symbol.iterator]();
                  !(n = (a = l.next()).done);
                  n = !0
                ) {
                  let u = a.value,
                    s = e.get(u),
                    c = [],
                    f = !0,
                    d = !1,
                    p = void 0;
                  try {
                    for (
                      var h, v = s[Symbol.iterator]();
                      !(f = (h = v.next()).done);
                      f = !0
                    ) {
                      const y = h.value.getDeepLink();
                      y && c.push(y);
                    }
                  } catch (e) {
                    (d = !0), (p = e);
                  } finally {
                    try {
                      !f && v.return && v.return();
                    } finally {
                      if (d) throw p;
                    }
                  }
                  c.length > 0 && (r.push("group=" + u), (r = r.concat(c)));
                }
              } catch (e) {
                (o = !0), (i = e);
              } finally {
                try {
                  !n && l.return && l.return();
                } finally {
                  if (o) throw i;
                }
              }
              return r.join("&");
            },
          },
          {
            key: "findGroups",
            value: function (e) {
              const t = new Map();
              if (!e) return t;
              let r = [].concat(d(e)),
                n = !0,
                o = !1,
                i = void 0;
              try {
                for (
                  var a, l = r[Symbol.iterator]();
                  !(n = (a = l.next()).done);
                  n = !0
                ) {
                  let u = a.value,
                    s = u.getAttribute("data-jplist-group"),
                    c = [];
                  t.has(s) && (c = t.get(s)),
                    c.push({
                      root: u,
                      items: [].concat(
                        d(u.querySelectorAll("[data-jplist-item]"))
                      ),
                      fragment: document.createDocumentFragment(),
                    }),
                    t.set(s, c);
                }
              } catch (e) {
                (o = !0), (i = e);
              } finally {
                try {
                  !n && l.return && l.return();
                } finally {
                  if (o) throw i;
                }
              }
              return t;
            },
          },
          {
            key: "findControls",
            value: function (e) {
              if (!e) return [];
              const t = [],
                r = e.querySelectorAll("[data-jplist-control]");
              if (r) {
                let n = !0,
                  o = !1,
                  i = void 0;
                try {
                  for (
                    var a, u = r[Symbol.iterator]();
                    !(n = (a = u.next()).done);
                    n = !0
                  ) {
                    const s = a.value;
                    if (s.getAttribute("data-jplist-control")) {
                      const c = new l.default(s);
                      t.push(c);
                    }
                  }
                } catch (e) {
                  (o = !0), (i = e);
                } finally {
                  try {
                    !n && u.return && u.return();
                  } finally {
                    if (o) throw i;
                  }
                }
              }
              return t;
            },
          },
          {
            key: "findControlGroups",
            value: function (e) {
              const t = new Map();
              if (e) {
                let r = !0,
                  n = !1,
                  o = void 0;
                try {
                  for (
                    var i, a = e[Symbol.iterator]();
                    !(r = (i = a.next()).done);
                    r = !0
                  ) {
                    let l = i.value,
                      u = [];
                    t.has(l.group) && (u = t.get(l.group)),
                      u.push(l),
                      t.set(l.group, u);
                  }
                } catch (e) {
                  (n = !0), (o = e);
                } finally {
                  try {
                    !r && a.return && a.return();
                  } finally {
                    if (n) throw o;
                  }
                }
              }
              return t;
            },
          },
          {
            key: "findSameNameControls",
            value: function (e, t) {
              const r = new Map();
              if (t) {
                let n = null;
                if (e.deepLinking)
                  n = u.default.getUrlParams(window.location.hash, e.hashStart);
                else if (e.storage) {
                  const o = s.default.get(e.storage, e.storageName);
                  n = u.default.getUrlParams(o, "");
                }
                let i = !0,
                  a = !1,
                  l = void 0;
                try {
                  for (
                    var c, f = t[Symbol.iterator]();
                    !(i = (c = f.next()).done);
                    i = !0
                  ) {
                    const d = c.value;
                    if (d.type && window.jplist.controlTypes.has(d.type)) {
                      const p = window.jplist.controlTypes.get(d.type);
                      if (p) {
                        let h = null;
                        (h = r.has(d.name)
                          ? r.get(d.name)
                          : new p(d.group, d.name, [], n)).addControl(d),
                          r.set(d.name, h);
                      }
                    }
                  }
                } catch (e) {
                  (a = !0), (l = e);
                } finally {
                  try {
                    !i && f.return && f.return();
                  } finally {
                    if (a) throw l;
                  }
                }
              }
              return r;
            },
          },
          {
            key: "splitByGroupAndName",
            value: function (t, r) {
              const o = new Map();
              if (!r) return o;
              let i = e.findControls(r),
                a = e.findControlGroups(i),
                l = !0,
                u = !1,
                s = void 0;
              try {
                for (
                  var c, f = a[Symbol.iterator]();
                  !(l = (c = f.next()).done);
                  l = !0
                ) {
                  let d = n(c.value, 2),
                    p = d[0],
                    h = d[1],
                    v = e.findSameNameControls(t, h),
                    y = [],
                    b = !0,
                    m = !1,
                    g = void 0;
                  try {
                    for (
                      var w, O = v.values()[Symbol.iterator]();
                      !(b = (w = O.next()).done);
                      b = !0
                    ) {
                      const _ = w.value;
                      y.push(_);
                    }
                  } catch (e) {
                    (m = !0), (g = e);
                  } finally {
                    try {
                      !b && O.return && O.return();
                    } finally {
                      if (m) throw g;
                    }
                  }
                  o.set(p, y);
                }
              } catch (e) {
                (u = !0), (s = e);
              } finally {
                try {
                  !l && f.return && f.return();
                } finally {
                  if (u) throw s;
                }
              }
              return o;
            },
          },
        ]),
        e
      );
    })();
    t.default = p;
  },
  function (e, t, r) {
    "use strict";
    Object.defineProperty(t, "__esModule", { value: !0 });
    const n = (function () {
        return function (e, t) {
          if (Array.isArray(e)) return e;
          if (Symbol.iterator in Object(e))
            return (function (e, t) {
              let r = [],
                n = !0,
                o = !1,
                i = void 0;
              try {
                for (
                  var a, l = e[Symbol.iterator]();
                  !(n = (a = l.next()).done) &&
                  (r.push(a.value), !t || r.length !== t);
                  n = !0
                );
              } catch (e) {
                (o = !0), (i = e);
              } finally {
                try {
                  !n && l.return && l.return();
                } finally {
                  if (o) throw i;
                }
              }
              return r;
            })(e, t);
          throw new TypeError(
            "Invalid attempt to destructure non-iterable instance"
          );
        };
      })(),
      o = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            (n.enumerable = n.enumerable || !1),
              (n.configurable = !0),
              "value" in n && (n.writable = !0),
              Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      })(),
      i = l(r(44)),
      a = l(r(1));
    function l(e) {
      return e && e.__esModule ? e : { default: e };
    }
    function u(e) {
      if (Array.isArray(e)) {
        for (var t = 0, r = Array(e.length); t < e.length; t++) r[t] = e[t];
        return r;
      }
      return Array.from(e);
    }
    const s = (function () {
      function e() {
        !(function (e, t) {
          if (!(e instanceof t))
            throw new TypeError("Cannot call a class as a function");
        })(this, e);
      }
      return (
        o(e, [
          {
            key: "init",
            value: function (e) {
              (this.settings = Object.assign(
                {},
                {
                  storage: "",
                  storageName: "jplist",
                  cookiesExpiration: -1,
                  deepLinking: !1,
                  hashStart: "#",
                },
                e
              )),
                (this.controls = i.default.splitByGroupAndName(
                  this.settings,
                  document.body
                )),
                (this.elements = document.querySelectorAll(
                  "[data-jplist-group]"
                )),
                (this.groups = i.default.findGroups(this.elements));
              for (
                let t = [].concat(
                    u(document.querySelectorAll("[data-jplist-item]"))
                  ),
                  r = 0;
                r < t.length;
                r++
              )
                t[r].jplistIndex = r;
              this.refresh("");
            },
          },
          {
            key: "refresh",
            value: function () {
              const e =
                  arguments.length > 0 && void 0 !== arguments[0]
                    ? arguments[0]
                    : "",
                t =
                  arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : void 0;
              i.default.apply(this.settings, this.controls, this.groups, e, t);
            },
          },
          {
            key: "resetControl",
            value: function (e) {
              if (e && this.controls) {
                let t = !0,
                  r = !1,
                  o = void 0;
                try {
                  for (
                    var i, l = this.controls[Symbol.iterator]();
                    !(t = (i = l.next()).done);
                    t = !0
                  ) {
                    let u = n(i.value, 2),
                      s = u[0],
                      c = u[1],
                      f = !0,
                      d = !1,
                      p = void 0;
                    try {
                      for (
                        var h, v = c[Symbol.iterator]();
                        !(f = (h = v.next()).done);
                        f = !0
                      ) {
                        const y = h.value,
                          b = y.controls.findIndex(function (t) {
                            return t.element === e;
                          });
                        if (b >= 0) {
                          const m = y.controls[b].element,
                            g = document.createElement("div");
                          g.innerHTML = m.initialHTML;
                          const w = g.firstChild;
                          return void (
                            m.parentNode &&
                            (m.parentNode.replaceChild(w, m),
                            y.controls.splice(b, 1),
                            y.addControl(new a.default(w)),
                            this.refresh(s))
                          );
                        }
                      }
                    } catch (e) {
                      (d = !0), (p = e);
                    } finally {
                      try {
                        !f && v.return && v.return();
                      } finally {
                        if (d) throw p;
                      }
                    }
                  }
                } catch (e) {
                  (r = !0), (o = e);
                } finally {
                  try {
                    !t && l.return && l.return();
                  } finally {
                    if (r) throw o;
                  }
                }
              }
            },
          },
          {
            key: "resetControls",
            value: function () {
              const e =
                arguments.length > 0 && void 0 !== arguments[0]
                  ? arguments[0]
                  : "";
              if (this.controls) {
                let t = !0,
                  r = !1,
                  o = void 0;
                try {
                  for (
                    var a, l = this.controls[Symbol.iterator]();
                    !(t = (a = l.next()).done);
                    t = !0
                  ) {
                    let u = n(a.value, 2),
                      s = (u[0], u[1]),
                      c = !0,
                      f = !1,
                      d = void 0;
                    try {
                      for (
                        var p, h = s[Symbol.iterator]();
                        !(c = (p = h.next()).done);
                        c = !0
                      ) {
                        let v = p.value,
                          y = !0,
                          b = !1,
                          m = void 0;
                        try {
                          for (
                            var g, w = v.controls[Symbol.iterator]();
                            !(y = (g = w.next()).done);
                            y = !0
                          ) {
                            const O = g.value;
                            O.element &&
                              O.element.initialHTML &&
                              (O.element.outerHTML = O.element.initialHTML);
                          }
                        } catch (e) {
                          (b = !0), (m = e);
                        } finally {
                          try {
                            !y && w.return && w.return();
                          } finally {
                            if (b) throw m;
                          }
                        }
                      }
                    } catch (e) {
                      (f = !0), (d = e);
                    } finally {
                      try {
                        !c && h.return && h.return();
                      } finally {
                        if (f) throw d;
                      }
                    }
                  }
                } catch (e) {
                  (r = !0), (o = e);
                } finally {
                  try {
                    !t && l.return && l.return();
                  } finally {
                    if (r) throw o;
                  }
                }
              }
              (this.controls = i.default.splitByGroupAndName(
                this.settings,
                document.body
              )),
                this.refresh(e);
            },
          },
          {
            key: "resetContent",
            value: function (e) {
              let t = !0,
                r = !1,
                o = void 0;
              try {
                for (
                  var a, l = this.groups[Symbol.iterator]();
                  !(t = (a = l.next()).done);
                  t = !0
                ) {
                  let s = n(a.value, 2),
                    c = (s[0], s[1]),
                    f = !0,
                    d = !1,
                    p = void 0;
                  try {
                    for (
                      var h, v = c[Symbol.iterator]();
                      !(f = (h = v.next()).done);
                      f = !0
                    ) {
                      const y = h.value,
                        b = i.default.getItemsFragment(y.items);
                      y.root.appendChild(b);
                    }
                  } catch (e) {
                    (d = !0), (p = e);
                  } finally {
                    try {
                      !f && v.return && v.return();
                    } finally {
                      if (d) throw p;
                    }
                  }
                }
              } catch (e) {
                (r = !0), (o = e);
              } finally {
                try {
                  !t && l.return && l.return();
                } finally {
                  if (r) throw o;
                }
              }
              e && e(this.groups),
                (this.elements = document.querySelectorAll(
                  "[data-jplist-group]"
                )),
                (this.groups = i.default.findGroups(this.elements));
              for (
                let m = [].concat(
                    u(document.querySelectorAll("[data-jplist-item]"))
                  ),
                  g = 0;
                g < m.length;
                g++
              )
                m[g].jplistIndex = g;
              this.refresh("");
            },
          },
        ]),
        e
      );
    })();
    t.default = s;
  },
  function (e, t, r) {
    "use strict";
    const n = S(r(45)),
      o = S(r(39)),
      i = S(r(38)),
      a = S(r(37)),
      l = S(r(36)),
      u = S(r(35)),
      s = S(r(34)),
      c = S(r(33)),
      f = S(r(30)),
      d = S(r(29)),
      p = S(r(28)),
      h = S(r(27)),
      v = S(r(26)),
      y = S(r(25)),
      b = S(r(24)),
      m = S(r(23)),
      g = S(r(22)),
      w = S(r(21)),
      O = S(r(20)),
      _ = S(r(18)),
      j = S(r(6)),
      P = S(r(17)),
      k = S(r(16)),
      x = S(r(15));
    function S(e) {
      return e && e.__esModule ? e : { default: e };
    }
    !(function () {
      if ("function" != typeof window.CustomEvent) {
        const e = function (e, t) {
          t = t || { bubbles: !1, cancelable: !1, detail: void 0 };
          const r = document.createEvent("CustomEvent");
          return r.initCustomEvent(e, t.bubbles, t.cancelable, t.detail), r;
        };
        (e.prototype = window.Event.prototype), (window.CustomEvent = e);
      }
      (window.jplist = window.jplist || {}),
        (window.jplist.controlTypes =
          window.jplist.controlTypes ||
          new Map([
            ["hidden-sort", o.default],
            ["sort-buttons", i.default],
            ["radio-buttons-sort", a.default],
            ["checkbox-sort", l.default],
            ["select-sort", u.default],
            ["dropdown-sort", s.default],
            ["pagination", c.default],
            ["textbox-filter", f.default],
            ["checkbox-text-filter", d.default],
            ["radio-buttons-text-filter", p.default],
            ["buttons-text-filter", h.default],
            ["select-filter", v.default],
            ["dropdown-filter", g.default],
            ["checkbox-path-filter", y.default],
            ["radio-buttons-path-filter", b.default],
            ["buttons-path-filter", m.default],
            ["buttons-range-filter", w.default],
            ["slider-range-filter", O.default],
            ["no-results", _.default],
            ["dropdown", j.default],
            ["layout", P.default],
            ["reset", k.default],
            ["counter", x.default],
          ]));
      const t = new n.default();
      (window.jplist.init = t.init.bind(t)),
        (window.jplist.refresh = t.refresh.bind(t)),
        (window.jplist.resetControls = t.resetControls.bind(t)),
        (window.jplist.resetControl = t.resetControl.bind(t)),
        (window.jplist.resetContent = t.resetContent.bind(t));
    })();
  },
  ,
  function (e, t) {},
  ,
  ,
  ,
  ,
  function (e, t) {},
]);
